import React from 'react';
import TabelaPaginada from "../../components/TabelaPaginada";
import moment from "moment";
import "moment/locale/pt";
import {useFormulario} from "../../components/Formularios/Provider";
import {useHistory} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import {post} from "../../YoubizProvider";
import cogoToast from "cogo-toast";

import "./styles.scss";

moment.locale("pt");

const NovosRegistos = () => {
    const formulario = useFormulario();
    const history = useHistory();
    let columns = [
        {
            dataField: "numero_colaborador",
            text: 'Id'
        }, {
            dataField: 'nome',
            text: 'Nome',
        }, {
            dataField: 'email',
            text: 'Email'
        }, {
            dataField: "telemovel",
            text: 'Telemóvel'
        }, {
            dataField: 'data_criacao',
            text: 'Data de Registo',
            formatter: data => moment(data).format("DD/MM/YYYY")

        }, {
            dataField: 'data_desativado',
            text: 'Estado',
            formatter(data_desativado) {
                return data_desativado ? <span style={{textTransform: "uppercase", backgroundColor: "#CBA91A40", color: "white", borderRadius: "7px", padding: "8px"}}>Inativo</span> : <span style={{textTransform: "uppercase", backgroundColor: "#4F8B1C40", color: "white", borderRadius: "7px", padding: "8px"}}>Ativo</span>;
            }
        }, {
            dataField: 'action',
            text: 'Ações',
            formatter: (_, utilizador, reload) => <div>
                <a className="link link_acao"
                   onClick={() => {
                       formulario.abrirFormulario("novo_colaborador", {
                           extra: {
                               id_utilizador: utilizador.id,
                               colaborador: utilizador,
                               acao: "novo_colaborador",
                               headerText: "O registo de " + utilizador.nome + " será agora convertido para colaborador.\n" +
                                   "Por favor, preencha o pedido de expedição dos primeiros cartões\n" +
                                   "que o novo colaborador irá receber!"
                           },
                           valoresIniciais: {
                               ...utilizador,
                               numero_telemovel_youbiz: utilizador.telemovel,
                               numero_ascendente: utilizador.telemovel_ascendente || "936362323",
                               comissoes_personalizadas: "false",
                               ativo: true
                           },
                           formularioSubmetido: reload
                       })
                   }
                   }
                >Criar Colaborador</a><br/>
                <a className="link link_acao" onClick={() => {
                    confirmAlert({
                        title: `Eliminar Registo`,
                        message: `Confirma que pretende eliminar o novo registo de '${utilizador.nome}'? A informação será completamente apagada.`,
                        buttons: [
                            {
                                label: 'Sim',
                                onClick: async () => {
                                    try {
                                        await post("/utilizador/" + utilizador.id + "/apagar");
                                        cogoToast.success('Utilizador removido com sucesso', {hideAfter: 5, position: 'top-right'});
                                        reload();
                                    } catch (e) {
                                        cogoToast.error(e.response?.data?.message || e.message, {hideAfter: 5, position: 'top-right'})
                                    }
                                }
                            },
                            {label: 'Cancelar'}
                        ]
                    });
                }}>Eliminar</a>
            </div>
        }
    ];

    return <div className={"NovosRegistos"}>
        <TabelaPaginada
            numero_resultados={6}
            dataUrl={"/utilizador/listar/colaborador_pendente"}
            columns={columns}
            title={"Novos Registos"}
            mostrarExportacao
        />
    </div>;
}

export default NovosRegistos;