import React from 'react';
import {Dropdown} from 'react-bootstrap';
import Logo from '../../assets/images/logo_youbiz.svg';
import {useYoubiz} from "../../YoubizProvider";
import {Link, NavLink} from "react-router-dom";
import cogoToast from "cogo-toast";

const toastoptions = {
    hideAfter: 5,
    position: 'top-right'
}

const Navbar = (props) => {
    const youbiz = useYoubiz();

    let menu = [
        {
            titulo: "Página Inicial",
            url: "/",
            exact: true
        },
        {
            titulo: "Gestão Financeira",
            url: "/gestao_financeira"
        }
    ];

    if (!youbiz.utilizador)
        menu = [];
    else if (youbiz.utilizador?.tipo === 'admin')
        menu.push(...[{
            titulo: "Comunidade",
            url: "/comunidade",
            submenu: [
                {
                    titulo: "Colaboradores",
                    url: "/colaboradores"
                },
                {
                    titulo: "Cartões",
                    url: "/cartoes"
                }
            ]
        },
            {
                titulo: "Gestão",
                url: "/gestao",
                submenu: [
                    {
                        titulo: "Pedidos de Cartões",
                        url: "/pedidos_cartoes"
                    },
                    {
                        titulo: "Produtos",
                        url: "/produtos"
                    },
                    {
                        titulo: "Gestão de Negócio",
                        url: "/gestao_negocio"
                    }
                ]
            }]);

    return <nav className="navbar">
        <div className="header-container logo-wrapper">
            <div className={"logo"}>
                <Link to="/pagina_inicial">
                    <img src={Logo} alt={"logo-youbiz"}/>
                </Link>
                <h5 className={"ml-4 my-0 d-none d-md-block"}>Gestão de negócio</h5>
            </div>
            {youbiz.utilizador && <Dropdown>
                <Dropdown.Toggle className="nav-link bg-transparent pr-0">
                    {youbiz.utilizador.nome && <p className="mb-1 text-right"><strong>{youbiz.utilizador.nome}</strong>
                    </p>}
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown">
                    <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0"
                                   onClick={() => {
                                       youbiz.logout();
                                       cogoToast.success('Fez logout com sucesso.', toastoptions);
                                   }}>
                        Terminar Sessão
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>}
        </div>
        <div className={"header-menu"}>
            <div className="header-container">
                {menu.length > 0 && <div className={"header-panels"}>
                    <div className={"d-flex flex-column flex-md-row"}>
                        {menu.map((item, index) => {
                            if (item.submenu)
                                return <Dropdown className={"header-panel-dropdown"}>
                                    <Dropdown.Toggle className="bg-transparent">
                                        <NavLink to={item.url} onClick={e => e.preventDefault()} className="header-panel">
                                            {item.titulo}
                                        </NavLink>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="navbar-dropdown">
                                        {item.submenu.map((subitem, index) => {
                                            return <Dropdown.Item>
                                                <NavLink to={item.url + subitem.url} exact={subitem.exact} className={"dropdown-item"}>
                                                    {subitem.titulo}
                                                </NavLink>
                                            </Dropdown.Item>
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>;
                            return <NavLink to={item.url} exact={item.exact} className="header-panel">
                                {item.titulo}
                            </NavLink>;
                        })}
                    </div>
                </div>}
            </div>
        </div>
    </nav>;
}

export default Navbar;
