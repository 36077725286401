import React, {useState} from "react";

import './styles.scss';

export default ({values, register, errors}) => {
    const [mostrarPassword, setMostrarPassword] = useState(false);

    return <div className={"NovoProdutoPopup"}>
        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.codigo?.message}>
                        Código*
                        <input ref={register({
                            required: "Por favor introduza um código para este produto"
                        })} name="codigo" placeholder="" type="text" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.nome?.message}>
                        Nome*
                        <input ref={register({
                            required: "Por favor indique o nome deste produto",
                        })} name="nome" placeholder="" type="text" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>
        <div className={"row"}>
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.quantidades?.message}>
                        Quantidades* <small>(separadas por vírgula)</small>
                        <input ref={register({
                            required: "Indique as quantidades disponíveis para este produto",
                            validate: value => {
                                if (value.replace(/[^0-9, ]/g, '') !== value) return "Deverá introduzir apenas números entre vírgulas";
                                let quantidades = value.replace(/[^0-9,]/g, '').split(",");
                                for (let q of quantidades)
                                    if (isNaN(q))
                                        return "Deverá introduzir apenas números entre vírgulas";
                                return true;
                            }
                        })} name="quantidades" placeholder="" type="email" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>
        <div className="form-check mt-4">
            <label className="form-check-label label-checkbox">
                <input name="ativado" type="checkbox" className="form-check-input" ref={register}/>
                <i className="input-helper"></i>
                Ativo
            </label>
        </div>
    </div>;
}