import cogoToast from "cogo-toast";
import {post} from "../../../../YoubizProvider";

const etapas = [
    {
        titulo: "",
        formulario: require('./0').default
    }
];

const toastoptions = {
    hideAfter: 5,
    position: 'top-right'
};

const submeter = async (valores, opcoes) => {
    if (!opcoes.extra?.resetToken) {
        await post(`/utilizador/recuperar_password/pedir`, valores);
        cogoToast.success('Pedido de recuperação de password submetido com sucesso. Caso este email esteja registado na Youbiz, receberá um email de recuperação.', toastoptions);
    } else {
        await post(`/utilizador/recuperar_password/definir_password`, valores);
        cogoToast.success('A password foi redefinida com sucesso, faça login com as novas credenciais', toastoptions);
    }
};

const RecuperarPassword = {
    titulo: "Recuperar Password",
    submeter,
    etapas
};

export default RecuperarPassword;