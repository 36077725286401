import React, {useState} from "react";

import './styles.scss';

export default ({register, errors}) => {
    const [mostrarPassword, setMostrarPassword] = useState(false);

    return <div className={"NovoProdutoPopup"}>
        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.titulo?.message}>
                        Descrição*
                        <input ref={register({
                            required: "Por favor introduza a descrição do link"
                        })} name="titulo" placeholder="" type="text" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.url?.message}>
                        URL*
                        <input ref={register({
                            required: "Por favor indique o URL do link, ex: https://youbiz.pt",
                        })} name="url" placeholder="" type="text" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="form-check">
                    <label className="form-check-label label-checkbox">
                        <input name="visivel" type="checkbox" className="form-check-input" ref={register}/>
                        <i className="input-helper"></i>
                        Visível
                    </label>
                </div>
            </div>
        </div>
    </div>;
}