import ImportarReport from "./importar_report";
import PedidoCartoes from "./pedido_cartoes";
import ExpedicaoCartoes from "./expedicao_cartoes";
import NovoProduto from "./novo_produto";
import EditarColaborador from "./editar_colaborador";
import EditarPagamento from "./editar_pagamento";
import LinkUtil from "./link_util";
import RecuperarPassword from "./recuperar_password";
import NovoColaborador from "./novo_colaborador";
import EditarDadosPessoais from "./editar_dados_pessoais";

const obterConfiguracao = (nome_formulario) => {
    let configuracao = ({
        importar_report: ImportarReport,
        pedido_cartoes: PedidoCartoes,
        expedicao_cartoes: ExpedicaoCartoes,
        novo_produto: NovoProduto,
        editar_colaborador: EditarColaborador,
        novo_colaborador: NovoColaborador,
        editar_pagamento: EditarPagamento,
        link_util: LinkUtil,
        recuperar_password: RecuperarPassword,
        editar_dados_pessoais: EditarDadosPessoais,
    })[nome_formulario]
    if (!configuracao)
        throw new Error("Não existe nenhuma configuração para o formulário '" + nome_formulario + "'");
    return configuracao;
};

export default obterConfiguracao;