import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Search} from 'react-bootstrap-table2-toolkit';

import './styles.scss';

const {SearchBar} = Search;

const defaultSorted = [{
    dataField: 'id',
    order: 'desc'
}];

const pageButtonRenderer = ({page, active, disabled, title, onPageChange}) => {
    const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
    };

    return <li className="page-item">
        <button type="button" className={"btn outlined" + (active ? " active" : "")}
                onClick={handleClick}>{page}</button>
    </li>;
};


const options = {
    pageButtonRenderer,
    alwaysShowAllBtns: true,
    withFirstAndLast: false,
    hideSizePerPage: true,
    prePageText: "Anterior",
    nextPageText: "Seguinte",
};

const DataTable = ({data, columns, title, showSearchBar, exportUrl, showNewButton, ...props}) => {

    return <div className={"DataTable card " + props.className}>
        <div className="card-padding">
            <div>
                <div className="d-flex mb-4 justify-content-between buttons-table align-items-start">
                    <p className={"font-weight-bold table-title primary-color mr-3 flex-grow-1"}>{title}</p>
                    <div className={"actions"}>
                        {props.filtersActions}
                    </div>
                </div>

                <BootstrapTable
                    remote
                    bootstrap4
                    keyField={"id"}
                    defaultSorted={defaultSorted}
                    pagination={props.paginationOptions ? paginationFactory({...options, ...props.paginationOptions}) : null}
                    data={data}
                    columns={columns}
                    wrapperClasses="table-responsive"
                    bodyClasses="primary-color"
                    bordered={false}
                    onTableChange={(type, {page}) => {
                        if (type === "pagination")
                            props.onPageChange(page);
                    }}
                    header
                />
            </div>
            {props.children}
        </div>
    </div>
}

export default DataTable;
