import {useFormContext} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {useFormulario} from "../Formularios/Provider";
import {get} from "../../YoubizProvider";

import './styles.scss';

const ProcurarColaborador = ({values, errors, register}) => {
    const {setValue} = useFormContext();

    const [resultados, setResultados] = useState(null);
    const [valorPesquisa, setValorPesquisa] = useState("");
    const formulario = useFormulario();

    useEffect(() => {
        if (!values.colaborador)
            return;
        let colaborador = JSON.parse(values.colaborador);
        setValorPesquisa(`${colaborador.nome} - ${colaborador.email}`);
    }, [values.colaborador]);

    return <div className={"ProcurarCliente"}>
        <div className="form-group">
            <label data-error={errors.colaborador?.message}>
                Colaborador
                <input placeholder="Pesquise por número de cartão, telemóvel, NIF ou e-mail" type="text" className="form-control form-control-sm"
                       value={valorPesquisa}
                       onChange={async e => {
                           let valorPesquisa = e.target.value;
                           setValorPesquisa(valorPesquisa);

                           if (valorPesquisa === '') {
                               setResultados(null);
                               return;
                           }

                           let colaboradores = await get("/utilizador/listar/colaborador", {
                               numero_resultados: 10,
                               pesquisa: valorPesquisa
                           });
                           setResultados(colaboradores.resultados)
                       }}
                />
            </label>
            <input name="colaborador" placeholder="ESCONDIDO" type="text" className="d-none" ref={register({
                required: "Por favor indique um colaborador"
            })}/>
        </div>
        {resultados && <div className={"resultados_cliente"}>
            {resultados.map(colaborador => {
                return <p onClick={() => {
                    setValue("colaborador", JSON.stringify(colaborador));
                    setResultados(null);
                }}>
                    {colaborador.nome}<br/>
                    <small>
                        {colaborador.email}<br/>
                        {colaborador.numero_colaborador}
                    </small>
                </p>
            })}

            {resultados.length === 0 && <p
                style={{lineHeight: "30px"}}>
                Não foram encontrados resultados
            </p>}
        </div>}
    </div>;
}

export default ProcurarColaborador;