import React, {useState} from 'react';

import './styles.scss';
import ProcurarProduto from "../../../ProcurarProduto";

export default ({values, register, errors, setValue}) => {
    const [produtosIniciais] = useState(values.produtos.length);
    const [numeroProdutos, setNumeroProdutos] = useState(values.produtos.length);

    return <div className={"NovaVenda"}>
        <input
            name={"pedido"}
            type="text"
            className="d-none"
            ref={register}/>
        <div>
            <div className="form-group mb-1 py-2">
                <p>
                    Faça a picagem dos cartões de cada um dos tarifários.
                </p>
            </div>
        </div>
        <div>
            {[...Array(numeroProdutos).keys()].map(idxProduto => {
                return <div className={"ExpedicaoPopup"}>
                    <div className="row">
                        <div className="col-12">
                            {idxProduto < produtosIniciais && <>
                                <input
                                    name={"produtos[" + idxProduto + "].quantidade"}
                                    type="text"
                                    className="d-none"
                                    ref={register}/>
                                <input
                                    name={"produtos[" + idxProduto + "].nome"}
                                    type="text"
                                    className="d-none"
                                    ref={register}/>
                                <input
                                    name={"produtos[" + idxProduto + "].id_produto"}
                                    type="text"
                                    className="d-none"
                                    ref={register}/>
                            </>}
                            <div className="form-group mb-0 my-3">
                                {idxProduto >= produtosIniciais ?
                                    <ProcurarProduto {...{
                                        errors,
                                        values,
                                        register,
                                        idxProduto
                                    }} /> :
                                    <label data-error={errors.produtos && errors.produtos[idxProduto]?.quantidade?.message}>
                                        <p><b>{values.produtos[idxProduto]?.quantidade}</b> x {values.produtos[idxProduto]?.nome}</p>
                                    </label>}
                            </div>
                        </div>
                        <div className="col-12 form-group">
                            <label data-error={errors.produtos?.[idxProduto]?.codigos_sim?.message}>
                            <textarea className="form-control form-control-sm" style={{width: "100%"}} rows="4"
                                      name={"produtos[" + idxProduto + "].codigos_sim"}
                                      onChange={e => {
                                          setValue("produtos[" + idxProduto + "].codigos_sim", e.target.value.replace(/\b0+/g, '').replace(/[^0-9\n]/g, ''));
                                      }}
                                      ref={register({
                                          validate: () => {
                                              let sims = values.produtos.map(p => p.codigos_sim?.trim().split(/\r?\n/).filter(s => s.trim() !== '')).filter(s => s).reduce((acc, curr) => acc.concat(...curr), [])
                                              return sims.length === sims.filter((s, i) => sims.indexOf(s) === i).length ? null : "Existem cartões repetidos"
                                          }
                                      })}>
                            </textarea>
                            </label>
                            <div id="the-count">Introduzidos
                                <b>
                                    <span id="current"> {values.produtos[idxProduto]?.codigos_sim?.trim().split(/\r?\n/).filter(s => s.trim() !== '').length || 0} </span>
                                    {values.produtos[idxProduto]?.quantidade && <>
                                        de <span id="maximum"> {values.produtos[idxProduto]?.quantidade}</span>
                                    </>}
                                </b>
                            </div>
                        </div>
                    </div>
                </div>;
            })}
            <div className={"mt-3"}>
                <button className={"btn btn-s btn-primary"} onClick={() => setNumeroProdutos(numeroProdutos + 1)}>+ Adicionar mais</button>
            </div>
        </div>
    </div>
}