import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {useYoubiz} from "./YoubizProvider";

import Login from "./pages/Login";
import Produtos from "./pages/Produtos";
import PoliticaPrivacidade from "./pages/PoliticaPrivacidade";
import GestaoFinanceira from "./pages/GestaoFinanceira";
import Colaboradores from "./pages/Colaboradores";
import NovosRegistos from "./pages/NovosRegistos";
import Cartoes from "./pages/Cartoes";
import PedidosCartoes from "./pages/PedidosCartoes";
import GestaoDeNegocio from "./pages/GestaoDeNegocio";
import PerfilColaborador from "./pages/PerfilColaborador";
import DashboardAdmin from "./pages/DashboardAdmin";
import GestaoFinanceiraColaborador from "./pages/GestaoFinanceiraColaborador";
import Contactos from "./pages/Contactos";
import CondicoesGerais from "./pages/CondicoesGerais";
import Registo from "./pages/Registo";

const Routes = (props) => {
    const youbiz = useYoubiz();

    if (youbiz.utilizador === null)
        return null;

    let routes = [];

    switch (youbiz.utilizador.tipo) {
        case "admin":
            routes = [
                <Route exact path={"/gestao_financeira"} component={GestaoFinanceira}/>,
                <Route exact path={"/comunidade/colaboradores"} component={Colaboradores}/>,
                <Route exact path={"/comunidade/colaboradores/novos"} component={NovosRegistos}/>,
                <Route exact path={"/comunidade/cartoes"} component={Cartoes}/>,
                <Route exact path={"/gestao/pedidos_cartoes"} component={PedidosCartoes}/>,
                <Route exact path={"/gestao/produtos"} component={Produtos}/>,
                <Route exact path={"/gestao/gestao_negocio"} component={GestaoDeNegocio}/>,
                <Route exact path={"/colaborador/:id"} component={PerfilColaborador}/>,
                <Route exact path={"/"} component={DashboardAdmin}/>,
            ];
            break;
        case "colaborador":
            routes = [
                <Route exact path={"/gestao_financeira"} component={GestaoFinanceiraColaborador}/>,
                <Route exact path={"/"} component={PerfilColaborador}/>
            ];
    }

    if (youbiz.utilizador)
        return <Switch>
            {routes}
            <Route exact path={"/contactos"} component={Contactos}/>
            <Route exact path={"/condicoes-gerais"} component={CondicoesGerais}/>
            <Route exact path={"/politica-privacidade"} component={PoliticaPrivacidade}/>
            <Redirect to={"/"}/>
        </Switch>;
    else
        return <Switch>
            <Route exact path={"/"} component={Login}/>
            <Route exact path={"/registo"} component={Registo}/>
            <Route exact path={"/contactos"} component={Contactos}/>
            <Route exact path={"/condicoes-gerais"} component={CondicoesGerais}/>
            <Route exact path={"/politica-privacidade"} component={PoliticaPrivacidade}/>
            <Redirect to={"/"}/>
        </Switch>
}

export default Routes;