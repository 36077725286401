import {post} from "../../../../YoubizProvider";
import cogoToast from "cogo-toast";

const etapas = [
    {
        titulo: "",
        formulario: require("./0").default
    }
];

const toastoptions = {
    hideAfter: 5,
    position: 'top-right'
}

const submeter = async (valores, opcoes) => {
    valores.quantidades = valores.quantidades.replace(/[^0-9,]/g, '').split(',').map(q => parseInt(q));
    if (opcoes.extra?.id_produto) {
        await post("/cartoes/produtos/" + opcoes.extra.id_produto + "/editar", valores);
        cogoToast.success('Produto editado com sucesso!', toastoptions);
    } else {
        await post("/cartoes/produtos/criar", valores);
        cogoToast.success('Produto criado com sucesso!', toastoptions);
    }
};

const NovoProduto = {
    titulo: "Novo produto",
    submeter,
    etapas
};

export default NovoProduto;