import React, {useEffect, useMemo, useRef, useState} from 'react';
import {get} from "../../YoubizProvider";

import DataTable from "../DataTable";

import "./styles.scss";
import DatePicker from "react-datepicker";
import moment from "moment";
import {Dropdown} from "react-bootstrap";

function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

const TabelaPaginada = (props) => {
    const numero_resultados = props.numero_resultados || 6;

    const [dados, setDados] = useState(null);

    const [filtros, setFiltros] = useState({
        ...props.filtro,
        pesquisa: props.pesquisaInicial || null,
        pagina: 0,
        numero_resultados: numero_resultados,
        ...((props.filters || []).reduce((acc, filter) => ({...acc, [filter.name]: filter.default}), {}))
    });
    const filtrosRef = useRef(filtros);

    const [pesquisa, setPesquisa] = useState(null);

    const timeout = useRef(null);

    const colunas = useMemo(() => {
        return props.columns.map(coluna => {
            return {
                ...coluna,
                formatter: coluna.formatter ? (data, row) => coluna.formatter(data, row, obterDados) : null,
                headerStyle: {
                    color: '#001737',
                    fontWeight: '700',
                    border: 'none',
                    ...coluna.headerStyle
                }
            };
        })
    }, [props.columns]);

    useEffect(() => {
        filtrosRef.current = filtros;
        obterDados();
    }, [filtros]);

    useEffect(() => {
        if (pesquisa === null)
            return;
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            setFiltros(curr => ({...curr, pesquisa, pagina: 0}))
        }, 400);
    }, [pesquisa]);

    useEffect(() => {
        setFiltros(curr => ({
            ...curr,
            numero_resultados: props.numero_resultados
        }))
    }, [props.numero_resultados]);

    const obterDados = async () => {
        if (props.fetchData) {
            const res = await props.fetchData(filtrosRef.current);
            setDados(res);
        } else {
            let clientes = await get(props.dataUrl, filtrosRef.current);
            setDados(clientes);
        }
    };

    let setFiltro = (chave, valor) => setFiltros(curr => ({...curr, [chave]: valor}));

    let urlExport = null;
    if (props.mostrarExportacao) {
        let params = new URLSearchParams();
        for (let key in props.filtro)
            params.append(key, props.filtro[key]);
        params.append("q", filtros.pesquisa);
        params.append("exportar_excel", 1);
        urlExport = process.env.REACT_APP_API_URL + props.dataUrl + "?" + params.toString();
    }

    if (!dados)
        return null;

    let filtersActions = [];

    if (props.enableRange)
        filtersActions.push(<div className={"range"}>
            <span className={"mr-3"}>De <DatePicker dateFormat="dd/MM/yyyy" placeholderText={"01/01/2000"} selected={filtros.range?.[0]} onChange={(value) => setFiltro("range", [value, filtros.range?.[1]])} className="form-control form-control-sm ml-1"/></span>
            <span className={"mr-3"}>Até <DatePicker dateFormat="dd/MM/yyyy" placeholderText={moment().format("DD/MM/yyyy")} selected={filtros.range?.[1]} onChange={(value) => setFiltro("range", [filtros.range?.[0], value])} className="form-control form-control-sm ml-1"/></span>
        </div>);

    if (props.showSearch !== false)
        filtersActions.push(<label className="search-label">
            <input type="text" className="form-control form-control-sm flex-grow-1" placeholder={props.searchTitle || 'Pesquisar'} value={pesquisa || props.pesquisaInicial || ''} onChange={(e) => setPesquisa(e.target.value)}/>
        </label>);

    if (props.newButtonShow)
        filtersActions.push(<button type="button" className="btn outlined ml-3 form-control-sm" onClick={() => props.newButtonClick({reload: obterDados})}>{props.newButtonText}</button>)

    if (props.extraActions)
        filtersActions.push(...props.extraActions.map(acao => {
            return <button type="button" className="btn outlined ml-3 form-control-sm" onClick={acao.action}>{acao.label}</button>
        }));

    if (props.filters) {
        props.filters.forEach(filter => {
            if (filter.type === 'select') {
                filtersActions.push(<label className={"filter-label"}>
                    <span>{filter.label}</span>
                    <select
                        value={filtros[filter.name]}
                        onChange={(e) => setFiltro(filter.name, e.target.value)}
                        className={"form-control form-control-sm"}>
                        {filter.options.map(option => <option value={option.value}>{option.label}</option>)}
                    </select>
                </label>)
            }
        });
    }

    if (urlExport)
        filtersActions.push(<Dropdown>
            <Dropdown.Toggle className={"export-btn form-control-sm"}>Exportar</Dropdown.Toggle>
            <Dropdown.Menu className="m-0">
                <Dropdown.Item href={urlExport} target="_blank">Excel</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>);

    return <div className={"TabelaPaginada " + (props.className || '')}>
        <DataTable
            children={props.children}
            data={dados.resultados}
            columns={colunas}
            paginationOptions={props.numero_resultados > 0 && dados.paginacao && {
                page: filtros.pagina + 1,
                sizePerPage: numero_resultados,
                totalSize: dados.paginacao.total_resultados
            }}
            title={props.title}
            filtersActions={filtersActions}
            onPageChange={page => setFiltro("pagina", page - 1)}
        />
    </div>
}

export default TabelaPaginada;