import React, {useMemo} from 'react';

export default ({values, register, errors}) => {
    let produtos = useMemo(() => {
        return values.produtos.map(p => {
            if (p.id)
                p = {...JSON.parse(p.id), codigos_sim: p.codigos_sim};
            p = {
                quantidade_pedida: p.quantidade,
                nome: p.nome,
                id: p.id_produto || p.id,
                codigos: p.codigos_sim?.trim().split(/\r?\n/).filter(s => s.trim() !== '')
            }
            if (!p.codigos)
                return null;
            return p;
        }).filter(p => p);
    }, [values.produtos]);

    return <div className={"ExpedicaoPopup"}>
        <div>
            <div>
                <div className="row m-0 py-2">
                    <div className="col-12">
                        {produtos.map(p => <div className="form-group mb-3 my-2">
                            <h4><b>{p.quantidade_pedida || p.codigos.length}</b> x {p.nome}</h4>
                            {p.quantidade_pedida && parseInt(p.quantidade_pedida) !== p.codigos.length && <p className={"mb-0"}>INTRODUZIDOS <b>{p.codigos.length} de {p.quantidade_pedida} <span style={{color: p.codigos.length > p.quantidade_pedida ? "#00A513" : "#B40000"}}>({p.codigos.length > p.quantidade_pedida ? "MAIS" : "MENOS"} {Math.abs(p.codigos.length - p.quantidade_pedida)} DO QUE O PEDIDO)</span></b></p>}
                            <p style={{color: "#A7AFB7"}}>{p.codigos.join(", ")}</p>
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
    </div>
}