import React from "react";

import './styles.scss';

export default ({values, register, errors}) => {
    return <div className={"NovoProdutoPopup"}>
        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.valor_custos_operacionais?.message}>
                        Custos Operacionais (C.O.)
                        <input ref={register} name="valor_custos_operacionais" type="number" min="0" step="0.01" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>
        <div className={"row"}>
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.valor_despesas_administrativas?.message}>
                        Despesas Administrativas (D.A.)
                        <input ref={register} name="valor_despesas_administrativas" type="number" min="0" step="0.01" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.valor_premio?.message}>
                        Prémios
                        <input ref={register} name="valor_premio" placeholder="" type="number" min="0" step="0.01" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>
    </div>;
}