import React from 'react';
import {Link} from "react-router-dom";

import './styles.scss';

const Footer = () => {
    return <div className={"Footer"}>
        <div className={"youbiz-info-container row flex-column text-center"}>
            <p><Link to={"/contactos"} className={"underline mr-4"}>Contactos</Link><Link to={"/politica-privacidade"} className={"underline mr-4"}>Política de Privacidade</Link><Link to={"/condicoes-gerais"} className={"underline"}>Condições Gerais</Link></p>
            <p className={"mt-0 mb-0"}>Copyright © {new Date().getFullYear()} Youbiz. Todos os direitos reservados.</p>
        </div>
    </div>
}

export default Footer;