import React, {useState} from "react";
import verificarNif from "../../../../functions/verificarNif";
import Visualizar from "../../../../assets/images/visualizar_icon.svg";
import Esconder from "../../../../assets/images/esconder_icon.svg";
import DatePicker from "react-datepicker";
import {Controller, useFormContext} from "react-hook-form";

import './styles.scss';

export default ({values, register, errors, extra = {}}) => {
    const [mostrarPassword, setMostrarPassword] = useState(false);
    const {control} = useFormContext();

    return <div className={"EditarDadosPessoaisPopup"}>

        <div className="modal-subtitle pb-3">Dados gerais</div>

        <div className={"row"}>
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.email?.message}>
                        Email
                        <input ref={register({
                            required: "Por favor indique o email",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "O email não é válido"
                            }
                        })} name="email" placeholder="email@youbiz.pt" type="email" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.telemovel?.message}>
                        Nº Contacto
                        <input ref={register({
                            required: "Por favor indique o número de telemóvel do utilizador",
                        })} name="telemovel" placeholder="910 000 000" type="text" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.data_nascimento?.message}>
                        Data de Nascimento
                        <Controller
                            control={control}
                            name="data_nascimento"
                            defaultValue={null}
                            rules={{
                                //required: "Por favor indique a data de nascimento do utilizador",
                            }}
                            render={({onChange, value}) => (
                                <DatePicker dateFormat="dd/MM/yyyy" placeholderText={"01/01/2000"} selected={value} onChange={onChange} className="form-control form-control-sm"/>
                            )}
                        />
                    </label>
                </div>
            </div>
        </div>

        <div className="modal-subtitle py-3">Morada</div>

        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.morada?.message}>
                        Morada
                        <input ref={register({
                            //required: "Por favor introduza a morada"
                        })} name="morada" placeholder="Rua do Ouro" type="text" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-4">
                <div className="form-group">
                    <label data-error={errors.codigo_postal?.message}>
                        Código Postal
                        <input ref={register({
                            //required: "Por favor introduza o código postal do utilizador"
                        })} name="codigo_postal" placeholder="3641-331" type="text" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-group">
                    <label data-error={errors.localidade?.message}>
                        Localidade
                        <input ref={register({
                            //required: "Por favor introduza a localidade da morada"
                        })} name="localidade" placeholder="Localidade" type="text" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>

        <div className="modal-subtitle py-3">Documentos de Identificação</div>

        <div className="row">
            <div className="col-md-4">
                <div className="form-group">
                    <select
                        ref={register({
                            //required: "Escoha o tipo de documento de identificação"
                        })}
                        name={"doc_identificacao_tipo"}
                        className={"form-control form-control-sm ok"}>
                        <option disabled selected value={""}>Tipo de Documento</option>
                        <option value={"BI"}>Bilhete de Identidade</option>
                        <option value={"CC"}>Cartão do Cidadão</option>
                        <option value={"PASSPORT"}>Passaporte</option>
                    </select>
                </div>
            </div>
            <div className="col-md-8">
                <div className="form-group">
                    <label data-error={errors.doc_identificacao_numero?.message}>
                        <input ref={register({
                            //required: "Por favor introduza o número do cartão de identificação"
                        })} name="doc_identificacao_numero" placeholder="Número do Documento" type="text" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-4">
                <div className="form-group">
                    <label data-error={errors.doc_identificacao_emissao?.message}>
                        Data de Emissão
                        <Controller
                            control={control}
                            name="doc_identificacao_emissao"
                            defaultValue={null}
                            rules={{
                                //required: "Por favor indique a data de emissão do documento de identificação",
                            }}
                            render={({onChange, value}) => (
                                <DatePicker dateFormat="dd/MM/yyyy" placeholderText={"01/01/2000"} selected={value} onChange={onChange} className="form-control form-control-sm"/>
                            )}
                        />
                    </label>
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-group">
                    <label data-error={errors.doc_identificacao_validade?.message}>
                        Data de Validade
                        <Controller
                            control={control}
                            name="doc_identificacao_validade"
                            defaultValue={null}
                            rules={{
                                //required: "Por favor indique a data de validade do documento de identificação",
                            }}
                            render={({onChange, value}) => (
                                <DatePicker dateFormat="dd/MM/yyyy" placeholderText={"01/01/2000"} selected={value} onChange={onChange} className="form-control form-control-sm"/>
                            )}
                        />
                    </label>
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-group">
                    <label data-error={errors.doc_identificacao_local_emissao?.message}>
                        Local de Emissão
                        <input ref={register({
                            //required: "Por favor introduza o local de emissão do documento de identificação"
                        })} name="doc_identificacao_local_emissao" placeholder="Local" type="text" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>

        <div className="modal-subtitle py-3">Dados de Faturação</div>

        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.nif?.message}>
                        Número de Identificação Fiscal (NIF)
                        <input ref={register({
                            //required: "Por favor introduza o número de contribuinte do utilizador",
                            validate: (valor) => {
                                if (!valor || verificarNif(valor))
                                    return true;
                                return "O número de contribuinte não é válido"
                            }
                        })} name="nif" placeholder="000 000 000" type="text" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.iban?.message}>
                        IBAN
                        <input ref={register({
                            //required: "Por favor introduza o IBAN do utilizador"
                        })} name="iban" placeholder="PT50 0035 0000 0000 0000 0000 0" type="text" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>

        <div className="modal-subtitle py-3">Alterar Password</div>

        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.password_atual?.message || errors.password_nova?.message} className={"w-100"}>
                        <div className="row">
                            <div className="col-md-6">
                                <label className={"password"}>
                                    <img src={mostrarPassword ? Esconder : Visualizar} className={"ver_password"} onClick={() => setMostrarPassword(!mostrarPassword)}/>
                                    <input ref={register({
                                        validate: (valor) => valor && !values.password_nova ? "Para alterar a password indique a password nova" : true
                                    })} name="password_atual" placeholder="Password Atual" type={mostrarPassword ? "text" : "password"} className="form-control form-control-sm"/>
                                </label>
                            </div>
                            <div className="col-md-6">
                                <label className={"password"}>
                                    <img src={mostrarPassword ? Esconder : Visualizar} className={"ver_password"} onClick={() => setMostrarPassword(!mostrarPassword)}/>
                                    <input ref={register({
                                        validate: (valor) => valor && !values.password_atual ? "Para alterar a password indique a password atual" : true
                                    })} name="password_nova" placeholder="Nova password" type={mostrarPassword ? "text" : "password"} className="form-control form-control-sm"/>
                                </label>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        </div>

        <div className="modal-subtitle py-4">Notificações</div>

        <div className="row">
            <div className="col-md-12">
                <div className="form-check">
                    <label className="form-check-label label-checkbox">
                        <input name="notificacao_sms" type="checkbox" className="form-check-input" ref={register}/>
                        <i className="input-helper"></i>
                        Receber atualizações de negócio via SMS
                    </label>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="form-check mt-2">
                    <label className="form-check-label label-checkbox">
                        <input name="notificacao_email" type="checkbox" className="form-check-input" ref={register}/>
                        <i className="input-helper"></i>
                        Receber atualizações de negócio via Email
                    </label>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-12">
                <div className="form-check mt-2">
                    <label className="form-check-label label-checkbox">
                        <input name="marketing" type="checkbox" className="form-check-input" ref={register}/>
                        <i className="input-helper"></i>
                        Aceito os termos de marketing
                    </label>
                </div>
            </div>
        </div>
    </div>;

}