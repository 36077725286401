import React from 'react';
import TabelaPaginada from "../../components/TabelaPaginada";
import moment from "moment";
import "moment/locale/pt";

import "./styles.scss";
import {Link} from "react-router-dom";

moment.locale("pt");

const Cartoes = () => {
    let columns = [
        {
            dataField: "num_telemovel",
            text: 'Nº Telemóvel'
        }, {
            dataField: 'sim',
            text: 'SIM',
        }, {
            text: 'Ascendente',
            formatter(_, cartao) {
                if (!cartao.vendedor)
                    return null;
                return <Link to={`/colaborador/${cartao.vendedor_id}`} className={"primary-color underline m-0 text-nowrap"}>{cartao.vendedor_nome}</Link>
            }
        }, {
            dataField: 'data_criacao',
            text: 'Data de Criação',
            formatter: data => moment(data).format("DD/MM/YYYY")
        }
    ];

    let ano_atual = new Date().getFullYear();
    let ano_inicio = 2014;
    let filters = [{
        label: "Ano",
        name: "ano",
        type: "select",
        default: ano_atual,
        options: new Array(ano_atual - ano_inicio + 1).fill(0)
            .map((_, idx) => ({value: ano_atual - idx, label: ano_atual - idx}))
    }]

    return <div className={"Cartoes"}>
        <TabelaPaginada
            numero_resultados={12}
            dataUrl={"/cartoes/listar"}
            columns={columns}
            filters={filters}
            title={"Cartões"}
            mostrarExportacao
        />
    </div>;
}

export default Cartoes;