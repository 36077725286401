import React, {useEffect, useState} from 'react';
import {Button, Form, FormGroup} from 'react-bootstrap';
import {useForm} from "react-hook-form";
import {post, useYoubiz} from "../../YoubizProvider";
import cogoToast from "cogo-toast";

import './styles.scss'

import Visualizar from "../../assets/images/visualizar_icon.svg";
import Esconder from "../../assets/images/esconder_icon.svg";
import {useFormulario} from "../../components/Formularios/Provider";
import {Link, useHistory, useLocation} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const toastoptions = {
    hideAfter: 5,
    position: 'top-right'
}

const Registo = () => {
    const {register, handleSubmit, setError, errors, setValue} = useForm();
    const [mostrarPassword, setMostrarPassword] = useState(false);
    const youbiz = useYoubiz();
    const formulario = useFormulario();
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        let params = new URLSearchParams(location.search);
        let resetToken = params.get("reset");
        if (resetToken)
            formulario.abrirFormulario("recuperar_password", {
                extra: {
                    resetToken
                }
            })
    }, [location]);

    const onSubmit = async data => {
        try {
            await post("/utilizador/registo", data)
            cogoToast.success('Fez registo com sucesso. Aguarde contacto por parte da equipa.', toastoptions);
            history.push("/")
        } catch (error) {
            if (error.response.data) {
                setError("button", {
                    type: "server",
                    message: error.response.data.message
                });
            }
        }
    };

    return <div className={"Registo"}>
        <h4 className={"mb-4 font-weight-bold text-center primary-color"}>Crie a sua conta Youbiz!</h4>
        <div className={"form"}>
            <Form noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                <FormGroup className={"mb-3"}>
                    <Form.Label>Nome Completo</Form.Label>
                    <Form.Control name={"nome"} ref={register({
                        required: true
                    })}/>
                    {errors.nome?.type === "required" && <p className={"error-message"}>Por favor introduza o seu nome completo</p>}
                </FormGroup>
                <FormGroup className={"mb-3"}>
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control name={"email"} ref={register({
                        required: true
                    })}/>
                    {errors.email?.type === "required" && <p className={"error-message"}>Introduza o seu e-mail</p>}
                </FormGroup>
                <FormGroup className={"mb-3"}>
                    <Form.Label>Telemóvel</Form.Label>
                    <Form.Control name={"telemovel"} ref={register({
                        required: true
                    })}/>
                    {errors.telemovel?.type === "required" && <p className={"error-message"}>Introduza o seu contacto telefónico</p>}
                </FormGroup>
                <Form.Group className={"mb-3 password"} controlId={"password"}>
                    <Form.Label>Password</Form.Label>
                    <Form.Control name={"password"} type={mostrarPassword ? "text" : "password"} ref={register({
                        required: true
                    })}/>
                    <img src={mostrarPassword ? Esconder : Visualizar} className={"ver_password"} onClick={() => setMostrarPassword(!mostrarPassword)}/>
                    {errors.password && <p className={"error-message"}>A password é obrigatória</p>}
                </Form.Group>
                <Form.Group className={"mb-3"}>
                    <Form.Label>Número do vendedor que recomendou o negócio</Form.Label>
                    <Form.Control name={"numero_ascendente"} ref={register({
                        required: "Quem lhe recomendou este negócio?",
                        validate: value => {
                            if (value.trim().length > 0 && (value.length !== 9 || value.replace(/[^0-9.]/g, '').length !== 9))
                                return "Introduza apenas 9 números"
                            return true
                        }
                    })}/>
                    {errors.numero_ascendente && <p className={"error-message"}>{errors.numero_ascendente.message}</p>}
                </Form.Group>
                <Form.Group className={"mb-3"}>
                    <Form.Label>Morada</Form.Label>
                    <Form.Control name={"morada"} ref={register({
                        required: true
                    })}/>
                    {errors.morada && <p className={"error-message"}>Introduza a sua morada</p>}
                </Form.Group>
                <Form.Group className={"mb-3"}>
                    <Form.Label>Código Postal</Form.Label>
                    <Form.Control name={"codigo_postal"} ref={register({
                        required: true
                    })}/>
                    {errors.codigo_postal && <p className={"error-message"}>Introduza o código postal</p>}
                </Form.Group>
                <Form.Group className={"m-0"}>
                    <Form.Label>Localidade</Form.Label>
                    <Form.Control name={"localidade"} ref={register({required: true})}/>
                    {errors.localidade && <p className={"error-message"}>Introduza a localidade</p>}
                </Form.Group>
                <div className="form-check mt-4">
                    <label className="form-check-label label-checkbox">
                        <input name="tos_pp" type="checkbox" className="form-check-input" ref={register({
                            required: "É obrigatório aceitar os termos e condições e a política de privacidade"
                        })}/>
                        <i className="input-helper"/> Aceito as <Link target={"_blank"} to={"/politica-privacidade"}>Política de Privacidade</Link> e as <Link target={"_blank"} to={"/condicoes-gerais"}>Condições Gerais do negócio</Link>
                        {errors.tos_pp && <p className={"error-message"}>{errors.tos_pp.message}</p>}
                    </label>
                </div>
                <div className="form-check">
                    <label className="form-check-label label-checkbox">
                        <input name="notificacao_sms" type="checkbox" className="form-check-input" ref={register}/>
                        <i className="input-helper"/> Pretendo receber notificações acerca do meu negócio por SMS
                    </label>
                </div>
                <div className="form-check">
                    <label className="form-check-label label-checkbox">
                        <input name="notificacao_email" type="checkbox" className="form-check-input" ref={register}/>
                        <i className="input-helper"/> Pretendo receber notificações acerca do meu negócio por e-mail
                    </label>
                </div>
                <div className="form-check">
                    <label className="form-check-label label-checkbox">
                        <input name="marketing" type="checkbox" className="form-check-input" ref={register}/>
                        <i className="input-helper"/> Aceito os termos de marketing e pretendo receber notícias acerca da YouBiz
                    </label>
                </div>
                <label className={"mt-4"}>
                    <input type="hidden" name={"captcha"} ref={register({
                        required: "Clique na caixa de verificação"
                    })}/>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                        onChange={result => {
                            setValue("captcha", result);
                        }}
                    />
                    {errors.captcha && <p className={"error-message"}>Clique na caixa de verificação</p>}
                </label>
                <div className={"text-center mt-4"}>
                    <Button name={"button"} className={"pt-2 pb-2 btn-block btn-registo"} variant="Registo" type={"submit"} ref={register}>
                        Começar!
                    </Button>
                    {errors.button?.type === "server" && <p className={"mb-0 error-message"}>{errors.button?.message}</p>}
                </div>
                <div className={"mb-3 mt-3 text-center"}>
                    <Link className={"text-gray"} style={{textDecoration: "underline"}} to={"/"}>Já tem uma conta? Login</Link>
                </div>
            </Form>
        </div>
    </div>
}

export default Registo;