import React, {useEffect, useMemo, useRef, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import ItemsCarousel from 'react-items-carousel';
import cogoToast from "cogo-toast";

const toastoptions = {
    hideAfter: 5,
    position: 'top-right'
}

const WrapperFormulario = ({configuracao, valoresIniciais = {}, ...props}) => {
    const [idxEtapa, setIdxEtapa] = useState(0);
    const [aEnviar, setAEnviar] = useState(false);

    const camposEtapas = useRef([]);
    const formMethods = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: valoresIniciais,
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: true,
    });
    const {register, handleSubmit, errors, watch, trigger, setValue} = formMethods;
    const values = watch();

    const mostrarEtapa = async (idx) => {
        let validacao = null;
        if (idx > idxEtapa)
            validacao = await trigger([...new Set(camposEtapas.current[idxEtapa])]);
        if (configuracao.podeNavegar ? configuracao.podeNavegar({
            resultado_validacao: validacao,
            etapa_atual: idxEtapa,
            etapa_proxima: idx,
            valores: values
        }) === false : validacao === false)
            return;

        if (idx > -1 && idx < configuracao.etapas.length) {
            setIdxEtapa(idx);
        }
    };
    const anterior = () => mostrarEtapa(idxEtapa - 1);
    const seguinte = () => mostrarEtapa(idxEtapa + 1);

    useEffect(() => {
        configuracao.aoNavegar && configuracao.aoNavegar({
            etapa_atual: idxEtapa,
            valores: values,
            setValue
        });
    }, [idxEtapa]);

    let props_formulario = useMemo(() => ({
        valoresIniciais,
        anterior,
        seguinte,
        mostrarEtapa,
        extra: props.extra,
        submeter: () => {
            if (!configuracao.submeter)
                throw new Error("Callback de submissão não está definido para o formulário " + props.nome_formulario);
            handleSubmit(async (values) => {
                setAEnviar(true);
                try {
                    let resultado = await configuracao.submeter(values, {configuracao, valoresIniciais, ...props});
                    // SUBMETIDO
                    props.fecharFormulario();
                    props.formularioSubmetido && props.formularioSubmetido(resultado);
                } catch (e) {
                    setAEnviar(false);
                    if (!e.response)
                        console.error(e);
                    else if (e.response.status === 500) {
                        cogoToast.error('Ocorreu um erro, por favor tente mais tarde.', toastoptions)
                    } else {
                        cogoToast.error(`${e.response.data.message}.`, toastoptions)
                    }
                }
            })()
        }
    }), [idxEtapa]);

    let mostrar_etapas = configuracao.etapas.length > 1;

    return <FormProvider  {...formMethods} >
        <div className={"WrapperFormulario " + (mostrar_etapas ? "com_etapas" : "sem_etapas")}>
            {mostrar_etapas && <div className={"selecao_etapa"}>{configuracao.etapas.map((etapa, idx) => {
                return <button
                    type="button"
                    className={"btn btn-sm btn-primary btn-etapa-form" + (idx === idxEtapa ? " active" : "")}
                    onClick={() => mostrarEtapa(idx)}>
                    {etapa.titulo}
                </button>
            })}</div>}
            <ItemsCarousel
                requestToChangeActive={mostrarEtapa}
                activeItemIndex={idxEtapa}
                numberOfCards={1}
                gutter={0}
                disableSwipe={true}
                classes={{itemsWrapper: "itemsWrapper", itemWrapper: "itemWrapper", wrapper: "carousselWrapper"}}
            >
                {configuracao.etapas.map((etapa, idx) => {
                    if (!camposEtapas.current[idx])
                        camposEtapas.current[idx] = [];

                    let FormularioEtapa = etapa.formulario;
                    return <div className={"WrapperEtapa"} key={props.nome_formulario + ":" + idx}>
                        <div className={"formulario"}>
                            <FormularioEtapa
                                key={idx}
                                {...props_formulario}
                                values={values}
                                errors={errors}
                                setValue={setValue}
                                register={(conf) => {
                                    if (!conf)
                                        return;

                                    if (conf.nodeType === 1) {
                                        // Is dom element
                                        camposEtapas.current[idx].push(conf.name);
                                        register(conf);
                                        return;
                                    }

                                    return (ref) => {
                                        if (!ref)
                                            return;

                                        camposEtapas.current[idx].push(ref.name);
                                        register(conf)(ref);
                                    }
                                }}/>
                        </div>
                        <div className={"d-flex justify-content-between acoes"}>
                            <div>
                                {props.footerLeft}
                            </div>
                            <div>
                                {etapa.botaoAnterior !== false && <button
                                    type="button"
                                    className="btn btn-s px-4 btn-outline-dark"
                                    onClick={async () => {
                                        if (idx === 0)
                                            props.fecharFormulario();
                                        else
                                            props_formulario.anterior();
                                    }}
                                >
                                    {etapa.labelAnterior || (idx === 0 ? "Cancelar" : "Anterior")}
                                </button>}
                                <button
                                    type="button"
                                    className={"btn btn-s px-4 btn-primary ml-2" + (props.dangerButton ? " button-danger" : "") + (aEnviar ? " disabled" : "")}
                                    onClick={() => {
                                        if (aEnviar)
                                            return;
                                        if (idx === configuracao.etapas.length - 1)
                                            props_formulario.submeter();
                                        else {
                                            props_formulario.seguinte();
                                        }
                                    }}>
                                    {idx === configuracao.etapas.length - 1 ? (etapa.labelSumbeter || props.labelSubmeter || "Guardar") : (etapa.labelSeguinte || "Seguinte")}
                                </button>
                            </div>
                        </div>
                    </div>;
                })}
            </ItemsCarousel>
        </div>
    </FormProvider>;
};

export default WrapperFormulario;