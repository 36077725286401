import React, {useEffect, useState} from "react";
import axios from "axios";

const axios_instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true
});

const YoubizContext = React.createContext({});

const get = async (url, data) => {
    return (await axios_instance.get(url, {params: data})).data;
};

const post = async (url, data) => {
    return (await axios_instance.post(url, data)).data;
};

const fullURL = (url) => {
    return process.env.REACT_APP_API_URL + url;
}

const YoubizProvider = (props) => {
    const [utilizador, setUtilizador] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                let utilizador = await get("/utilizador/detalhes");
                setUtilizador(utilizador.utilizador);
            } catch (e) {
                setUtilizador(false);
            }
        })();
    }, []);

    const login = async (dados) => {
        let utilizador = await post("/utilizador/login", dados);
        setUtilizador(utilizador.utilizador);
    };

    const logout = async () => {
        await post("/utilizador/logout");
        setUtilizador(false);
    };

    return <YoubizContext.Provider value={{get, post, login, logout, utilizador}}>
        {props.children}
    </YoubizContext.Provider>
};

const useYoubiz = () => React.useContext(YoubizContext);

export default YoubizProvider;
export {useYoubiz, get, post, fullURL};