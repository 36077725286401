import {post} from "../../../../YoubizProvider";
import cogoToast from "cogo-toast";

const etapas = [
    {
        titulo: "1. Cartões a expedir",
        formulario: require('./0').default
    },
    {
        titulo: "2. Resumo",
        formulario: require('./1').default
    }
];

const toastoptions = {
    hideAfter: 5,
    position: 'top-right'
}

const submeter = async (valores) => {
    let produtos = valores.produtos.map(p => {
        if (p.id)
            p = {...JSON.parse(p.id), codigos_sim: p.codigos_sim};
        p = {
            id: p.id_produto || p.id,
            codigos: p.codigos_sim?.trim().split(/\r?\n/).filter(s => s.trim() !== '')
        }
        if (!p.codigos)
            return null;
        return p;
    }).filter(p => p);

    await post(`/cartoes/pedidos/${valores.pedido}/expedir`, {
        cartoes_expedidos: produtos
    });

    cogoToast.success('Cartões expedidos com sucesso', toastoptions);
};

const ExpedicaoCartoes = {
    titulo: "Expedição de cartões",
    submeter,
    etapas
};

export default ExpedicaoCartoes;