let estados = {
    em_aberto: {
        designacao: "Em Aberto",
        cor: "#95B5D4"
    },
    aguarda_recibo: {
        designacao: "Aguarda Recibo",
        cor: "#F1E9C8"
    },
    pendente: {
        designacao: "A Pagamento",
        cor: "#D9D6D3"
    },
    vazio: {
        designacao: "Nada a Pagar",
        cor: "#BFD7AA"
    },
    pago: {
        designacao: "Pago",
        cor: "#BFD7AA"
    },
    anulado: {
        designacao: "Anulado",
        cor: "#d9d6d6"
    },
    caducado: {
        designacao: "Caducado",
        cor: "#dc6d6d"
    }
};

export default estados;