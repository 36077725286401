import {post} from "../../../../YoubizProvider";
import cogoToast from "cogo-toast";

const etapas = [
    {
        titulo: "",
        formulario: require("./0").default
    }
];

const toastoptions = {
    hideAfter: 5,
    position: 'top-right'
}

const submeter = async (valores, opcoes) => {
    if (opcoes.extra?.id_link) {
        await post(`/negocio/links_uteis/${opcoes.extra.id_link}/editar`, valores);
        cogoToast.success('Link alterado com sucesso', toastoptions);
    } else {
        await post("/negocio/links_uteis/criar", valores);
        cogoToast.success('Link adicionado com sucesso', toastoptions);
    }
};

const LinkUtil = {
    titulo: "Novo link útil",
    submeter,
    etapas
};

export default LinkUtil;