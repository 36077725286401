import React from 'react';

import './styles.scss';

const Contactos = () => {
    return <div className={"Contactos"}>
        <div className={"card mb-3"}>
            <div className="card-padding">
                <p className={"font-weight-bold table-title primary-color mr-3 flex-grow-1"}>Condições Gerais de Prestação de Serviços</p>
                <p className={"table-sub-title primary-color mr-3 flex-grow-1"}>CONDIÇÕES GERAIS DE PRESTAÇÃO DE SERVIÇOS YOUBIZ TELECOM – CONDIÇÕES GERAIS DO FUNCIONAMENTO DO CONTRATO DE COMISSÃO PELA YOUBIZ, LDA., PESSOA COLETIVA NÚMERO 509000495, MATRICULADA NA CONSERVATÓRIA DO REGISTO CIVIL DE LISBOA, COM O CAPITAL SOCIAL DE 5.000,00€ E SEDE NA AVENIDA DO BRASIL, Nº35 – 1º B, 1700-062 LISBOA.</p>
                <p>
                    <ol>
                        <li>As presentes condições gerais visam regular os termos e condições por que se rege o funcionamento do contrato de comissão Youbiz Telecom, em regime de prestação de serviços, celebrado entre a Youbiz, Lda., adiante designada Promotor e o cliente de Marketing de Rede, devidamente registado no website https://www.telecom.youbiz.pt/, adiante designado cliente ou comissionista.</li>
                        <li>A Youbiz Lda. é uma sociedade parceira da NOS COMUNICAÇÕES S.A., líder no canal de marketing de Rede.</li>
                        <li>O contrato de comissão Youbiz Telecom tem por base a criação de uma rede de colaboradores, vulgo comissionistas, que se dedica à promoção dos cartões pré-pagos da NOS COMUNICAÇÕES S.A., utilizando o Marketing de Rede como meio de comunicação com o mercado.</li>
                        <li>Para efeitos do presente contrato consideram-se comissionistas todos os interessados na atividade de promoção dos cartões pré-pagos da NOS COMUNICAÇÕES S.A. que para tal efetuam o seu registo como utilizadores no website https://www.telecom.youbiz.pt/ e que seja ele próprio cliente de um tarifário NOS COMUNICAÇÕES S.A.</li>
                        <li>A adesão ao presente contrato inicia-se com o registo do comissionista no website https://www.telecom.youbiz.pt/, validando o seu número NOS na sua área de negócio, enviando uma mensagem de texto escrita (SMS) para o número 936362323.</li>
                        <li>Após o registo, o comissionista receberá na morada indicada 10 (dez) cartões pré-pagos da NOS COMUNICAÇÕES S.A. gratuitamente, devendo angariar clientes da NOS COMUNICAÇÕES S.A. junto da sua rede de contatos.</li>
                        <li>Não será solicitado ao comissionista qualquer investimento na entrada ou durante o desenvolvimento do negócio.</li>
                        <li>O comissionista deverá exercer a promoção ativa dos tarifários «tudo», «livres» e «mundo», disponibilizados pela NOS COMUNICAÇÕES S.A. e integralmente descritos na área de negócio do comissionista.</li>
                        <li>A atividade de promoção exercida pelo comissionista assenta numa base de negócio de Marketing de Rede estratificada em «Equipa», composta pelos contatos «Diretos», que compreende todos os clientes e colaboradores angariados pelo comissionista, os contatos de «2ª linha», que compreende todos os clientes e colaboradores angariados pelo contatos «Diretos» e os contatos de «3ª Linha», que compreende todos os clientes e colaboradores angariados pelos contatos de «2ª Linha».</li>
                        <li>Por cada carregamento de um tarifário promovido pelo comissionista e validamente registado por um dos seus contatos, será gerada uma comissão que irá variar em função do seu patamar no negócio.</li>
                        <li>Existem três patamares de comissões, «Consultor», que compreende alcançar 1 a 24 carregamentos na «Equipa», «Executivo», que compreende alcançar 25 a 299 carregamentos na «Equipa» e «Coordenador», que compreende atingir 300 ou mais carregamentos na «Equipa».</li>
                        <li>Os patamares são calculados com os resultados do último dia de cada mês, tendo em consideração número de carregamentos efetuados nos seus tarifários pelos membros da «Equipa» do comissionista. A partir do momento em que o comissionista atinge um patamar superior, o mesmo ficará consolidado, não podendo descer ao nível de comissão inferior.</li>
                        <li>O patamar «Consultor» permite auferir uma percentagem de 10% do valor do carregamento do tarifário efetuado por um contato «Direto». O patamar «Executivo» permite auferir uma percentagem de 15% do valor do carregamento do tarifário efetuado por um contato «Direto» e 5% do valor do carregamento do tarifário efetuado por um contato «2ª Linha». O patamar «Coordenador» permite auferir uma percentagem de 20% do valor do carregamento do tarifário efetuado por um contato «Direto», 7,5% do valor do carregamento do tarifário efetuado por um contato «2ª Linha» e 5% do valor do carregamento do tarifário efetuado por um contato «3ª Linha».</li>
                        <li>A partir do momento em que exista um carregamento o comissionista ganha comissões durante 12 meses em todos os cartões carregados. Não existe qualquer limite de carregamento efetuados pela «Equipa» de contatos do comissionista.</li>
                        <li>O comissionista poderá auferir um incentivo semestral ou um incentivo mensal a acrescer ao valor da sua comissão, atribuído, respetivamente, aos 10 melhores comissionistas registados durante a semana ou o mês de referência, de acordo com os critérios de atribuição fixados pelo Promotor e publicitado no website https://www.telecom.youbiz.pt/.</li>
                        <li>Para controlar o seu negócio, o volume de angariações e carregamentos, o comissionista tem acesso ao «Gestor de Negócios YouBiz», para tanto deverá efetuar o login em http://plataforma.youbiz.pt/YB Telecom/welcome com os acessos que são enviados para o seu email pessoal após o registo.</li>
                        <li>O comissionista poderá reclamar o valor de comissão disponível na sua área de negócio, após o carregamento do tarifário efetuado pelo membro da sua «Equipa», a partir do momento em que o mesmo esteja disponibilizado no saldo da sua conta corrente.</li>
                        <li>O Valor de comissão calculado e devido nos termos do número anterior apenas poderá ser pago mediante a emissão do respetivo justificativo por parte do comissionista, ao qual se encontra obrigado, seja este fatura-recibo ou ato isolado. O incumprimento desta obrigação origina incumprimento contratual por parte do comissionista, desobrigando o Promotor do pagamento da comissão até emissão do justificativo por parte do comissionista.</li>
                        <li>A não emissão do documento justificativo, por parte do comissionista, no prazo de 15 dias a contar da interpelação para tal efeito pelo Promotor, origina incumprimento definitivo e a resolução do contrato com o comissionista, nada mais sendo devido pelo Promotor ao comissionista.</li>
                        <li>A partir da presente data, o crédito do comissionista perante o Promotor caduca no prazo de 24 (vinte e quatro) meses após o carregamento do tarifário efetuado pelo membro da sua «Equipa» se neste período de tempo o comissionista não solicitar o pagamento das comissões devidas pelo Promotor e não emitir o documento justificativo identificado em 17.</li>
                        <li>O Crédito do comissionista (vulgo comissões) caduca se há mais de 2 (dois) anos sobre a presente data não houver procedido à solicitação do seu pagamento nem emitido o competente documento justificativo, como mencionado em 20.</li>
                        <li>O presente contrato não gera qualquer vínculo ou relação jurídica laboral quer com o Promotor, quer com a NOS COMUNICAÇÕES S.A., declarando desde já o comissionista ter tomado conhecimento desta informação. O comissionista delcara que não se poderá apresentar a clientes que pretende angariar para a sua «Equipa» como tendo um vínculo quer com o Promotor quer com NOS COMUNICAÇÕES S.A.</li>
                        <li>O comissionista desde já declara que a forma de exercício da sua atividade de comissionista é da sua inteira responsabilidade, devendo respeitar as informações de tarifário e demais informações referentes ao exercício do negócio Youbiz Telecom disponibilizado no website https://www.telecom.youbiz.pt/.</li>
                        <li>O Promotor não se responsabiliza por qualquer informação incorreta, omissão ou falsidade de informação veiculada pelo comissionista., a qual não lhe poderá ser assacada a qualquer título, nem à NOS COMUNICAÇÕES S.A.</li>
                        <li>O incumprimento definitivo das obrigações contratuais confere a qualquer uma das partes o direito à resolução do Contrato, após pré-aviso adequado de 8 (oito) dias, sem prejuízo da responsabilidade civil contratual ou extracontratual que se venha a apurar</li>
                        <li>O Promotor pode denunciar o presente contrato sem necessidade de aviso prévio se o comissionista, no prazo de 24 (vinte e quatro) meses permanecer em inatividade, não angariando qualquer cliente para a sua «Equipa». Nas demais formas de cessação da relação contratual valerá o disposto na Lei.</li>
                        <li>As presentes condições gerais produzem efeito na data da adesão pelo comissionista ao serviço Youbiz Telecom, após o registo no website https://www.telecom.youbiz.pt/.</li>
                        <li>O promotor poderá alterar as Condições Gerais com a antecedência prévia de 10 (dez) dias sobre a entrada em vigor das novas condições contratuais, publicitadas quer na página de facebook do Promotor, quer através do envio para o email registado do comissionista na sua área de negócio. Caso delas discorde, o comissionista dispõe de uma prazo de 10 (dez) dias para, por escrito, rescindir a relação contratual em causa, sem qualquer penalização associada, e solicitar o pagamento de qualquer valor em dívida de comissão, caso exista, produzindo efeitos a denúncia a partir da entrada em vigor das alterações contratuais.</li>
                        <li>Após publicitação na página de facebook do Promotor e envio para o email registado do comissionista na sua área de negócio, as partes desde já acordam que as presentes Condições Gerais se aplicam aos contratos atualmente em vigor, dispondo os comissionistas do mecanismo disposto em 27 para, querendo, denunciar o contrato.</li>
                        <li>As partes expressamente declaram que o presente contrato é redigido pela Lei Portuguesa e que, para a resolução de qualquer litígio emergente da aplicação deste contrato, é competente o Tribunal Judicial da Comarca de Lisboa, com a exclusão de qualquer outro.</li>
                        <br/>
                        <p>Lisboa, 22 de outubro de 2021.</p>
                    </ol>
                </p>
            </div>
        </div>

    </div>;
};

export default Contactos;