import React from 'react';
import TabelaPaginada from "../../components/TabelaPaginada";
import moment from "moment";
import "moment/locale/pt";

import "./styles.scss";
import {useFormulario} from "../../components/Formularios/Provider";
import {useHistory} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";
import {post} from "../../YoubizProvider";
import cogoToast from "cogo-toast";

moment.locale("pt");

const Produtos = () => {
    const formulario = useFormulario();
    const history = useHistory();
    let columns = [
        {
            dataField: "posicao",
            text: 'Pos'
        }, {
            dataField: "codigo",
            text: 'Código'
        }, {
            dataField: "nome",
            text: 'Nome'
        }, {
            dataField: "ativado",
            text: 'Ativo',
            formatter: ativado => ativado ? <span style={{textTransform: "uppercase", backgroundColor: "#4F8B1C40", color: "white", borderRadius: "7px", padding: "8px"}}>SIM</span> : <span style={{textTransform: "uppercase", backgroundColor: "#675B4F40", color: "white", borderRadius: "7px", padding: "8px"}}>NÃO</span>
        }, {
            text: 'Ações',
            formatter: (_, produto, reload) => {
                return <>
                    <a className="link link_acao"
                       onClick={() => {
                           formulario.abrirFormulario("novo_produto", {
                               extra: {
                                   id_produto: produto.id
                               },
                               valoresIniciais: produto,
                               formularioSubmetido: reload,
                               titulo: "Editar Produto"
                           })
                       }
                       }
                    >Editar ></a><br/>
                    <a className="link link_acao" onClick={() => {
                        confirmAlert({
                            title: `Apagar Produto?`,
                            message: `Confirma que pretende eliminar o produto '${produto.nome}'? Não é possível eliminar produtos associados a pedidos ou a cartões expedidos.`,
                            buttons: [
                                {
                                    label: 'Sim',
                                    onClick: async () => {
                                        try {
                                            await post("/cartoes/produtos/" + produto.id + "/apagar");
                                            cogoToast.success('Produto removido com sucesso', {hideAfter: 5, position: 'top-right'});
                                            reload();
                                        } catch (e) {
                                            cogoToast.error(e.response?.data?.message || e.message, {hideAfter: 5, position: 'top-right'})
                                        }
                                    }
                                },
                                {label: 'Cancelar'}
                            ]
                        });
                    }}>Apagar ></a>
                </>
            }
        }
    ];

    return <div className={"Produtos"}>
        <TabelaPaginada
            numero_resultados={12}
            dataUrl={"/cartoes/produtos/listar"}
            columns={columns}
            title={"Produtos"}
            mostrarExportacao
            newButtonShow={true}
            newButtonClick={() => {
                formulario.abrirFormulario("novo_produto", {
                    valoresIniciais: {
                        ativado: true
                    },
                    formularioSubmetido: (resultado) => history.push(`clientes/${resultado.idCliente}`)
                })
            }
            }
            newButtonText={"Novo produto"}
        />
    </div>;
}

export default Produtos;