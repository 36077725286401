import React from 'react';

import './styles.scss';
import {useFormulario} from "../../components/Formularios/Provider";
import {useHistory} from "react-router-dom";
import IconMorada from "../../assets/images/morada.svg";
import IconEmail from "../../assets/images/email.svg";
import IconTel from "../../assets/images/tel.svg";
import IconLink from "../../assets/images/link.svg";

const PoliticaPrivacidade = () => {
    const formulario = useFormulario();
    const history = useHistory();
    return <div className={"Contactos"}>
        <div className={"card mb-3"}>
            <div className="card-padding">
                <p className={"font-weight-bold table-title primary-color mr-3 flex-grow-1"}>Política de Privacidade</p>

                <p className={"table-sub-title primary-color mr-3 flex-grow-1"}>Responsável pelo Tratamento de Dados</p>

                <p>A YOUBIZ, LDA”, pessoa coletiva nº. 509000495, matriculada na Conservatória do Registo Comercial De Lisboa sob o mesmo número, com sede na Rua Rodrigues Faria, 103, Espaço LX FACTORY, Edifício I, Sala 2.06B, em Lisboa, é a Responsável pelo tratamento dos seus dados pessoais.</p>

                <p className={"table-sub-title primary-color mr-3 flex-grow-1"}>Finalidades do Tratamento</p>

                <p>Os dados pessoais que tratamos através desta página serão unicamente utilizados para as seguintes finalidades:
                1.    Comunicação com clientes e esclarecimento de dúvidas;
                2.    Processamento de pedidos de informação;
                3.    Comunicações de marketing direto (caso tenha consentido no tratamento dos seus dados pessoais para esta finalidade);
                4.    Solicitação de serviços/produtos;</p>

                <p className={"table-sub-title primary-color mr-3 flex-grow-1"}>Prazo de Conservação</p>

                <p>Conservamos os seus dados apenas durante o período que for necessário no âmbito da finalidade para a qual estes foram recolhidos.</p>

                <p className={"table-sub-title primary-color mr-3 flex-grow-1"}>Consentimento</p>

                <p>Ao consentir nesta política de privacidade, está a dar permissão para processar os seus dados pessoais especificamente para os fins identificados e selecionados. Se o consentimento for legalmente necessário para o tratamento de dados pessoais, o titular dos dados tem o direito de retirar o seu consentimento em qualquer altura, embora esse direito não comprometa a licitude do tratamento efetuado com base no consentimento previamente dado nem o tratamento posterior dos mesmos dados, baseado noutra base legal, como é o caso de uma obrigação legal a que a YOUBIZ esteja sujeita. Para retirar o seu consentimento, pode contactar-nos através de carta ou do endereço de e-mail geral@youbiz.pt.</p>

                <p className={"table-sub-title primary-color mr-3 flex-grow-1"}>Direitos dos Utilizadores</p>

                <p>O utilizador pode exercer a todo o tempo os seus direitos de acesso, retificação, apagamento, limitação, oposição e portabilidade, através de solicitação por qualquer dos seguintes meios:
                    <br/>
                Correio: Doca de Alcântara Armazém nº 3, 1300-299 Lisboa, Portugal
                    <br/>
                E-mail: geral@youbiz.pt
                    <br/>
                O titular poderá ainda apresentar reclamações sobre o tratamento de dados junto da Comissão Nacional de Proteção de Dados.</p>

                <p className={"table-sub-title primary-color mr-3 flex-grow-1"}>Transmissão de Dados</p>

                <p>O tratamento dos dados dos utilizadores poderá ser realizado por um prestador de serviços idóneo, contratado pela YOUBIZ. Quando tal sucede, a YOUBIZ toma as medidas adequadas, de forma a assegurar que as entidades que tenham acesso aos dados oferecem as mais elevadas garantias no que respeita à proteção de dados. Assim, qualquer subcontratado, tratará os dados pessoais dos Utilizadores, em nome e por conta da YOUBIZ, cumprindo rigorosamente as normas legais sobre proteção de dados pessoais, segurança da informação e demais normas aplicáveis.</p>

                <p className={"table-sub-title primary-color mr-3 flex-grow-1"}>Transferências Internacionais de Dados</p>

                <p>A YOUBIZ, poderá no, âmbito das finalidades descritas, transferir os seus dados do utilizador para fora do território do Espaço Económico Europeu (EEE). Nestes casos, cumprirá rigorosamente as disposições legais aplicáveis, nomeadamente à determinação da adequabilidade do(s) país(es) de destino no que respeita à proteção de dados pessoais e aos requisitos aplicáveis a tais transferências.</p>

                <p className={"table-sub-title primary-color mr-3 flex-grow-1"}>Carácter obrigatório dos Dados Solicitados</p>

                <p>Os dados que constam no registo de cliente que estejam identificados com um asterisco (*), serão de preenchimento obrigatório para cumprir a finalidade estabelecida. Como tal, se o utilizador não facultar os mesmos, a YOUBIZ não poderá atender ao pedido deste.</p>

                <p className={"table-sub-title primary-color mr-3 flex-grow-1"}>Decisões Automatizadas</p>

                <p>A YOUBIZ poderá elaborar perfis dos utilizadores, o que permitirá a adoção de decisões automatizadas, baseadas nas características pessoais, preferências, histórico de comportamento e localização. Nestes casos, o utilizador terá o direito de obter uma intervenção humana na decisão, de expressar o seu ponto de vista e de impugnar a decisão adotada.</p>

                <p className={"table-sub-title primary-color mr-3 flex-grow-1"}>Medidas de protecção dos Dados Pessoais</p>

                <p>A YOUBIZ aplica diversas medidas técnicas e organizativas adequadas para proteger os dados pessoais dos utilizadores.</p>

                <p className={"table-sub-title primary-color mr-3 flex-grow-1"}>Uso de “cookies”</p>

                <p>A política de Cookies encontra-se disponível no website.</p>

                <p className={"table-sub-title primary-color mr-3 flex-grow-1"}>Alterações à Politica de Privacidade</p>

                <p>Ocasionalmente, a YOUBIZ atualizará esta Política de Privacidade. Solicitamos-lhe que reveja periodicamente este documento para se manter atualizado.</p>

            </div>
        </div>
    </div>;
};

export default PoliticaPrivacidade;