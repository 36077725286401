import React, {useState} from 'react';
import TabelaPaginada from "../../components/TabelaPaginada";
import formatarDinheiro from "../../functions/formatar_dinheiro";
import {useFormulario} from "../../components/Formularios/Provider";
import {Link, useHistory} from "react-router-dom";
import moment from "moment";
import "moment/locale/pt";
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {post} from "../../YoubizProvider";
import "font-awesome/css/font-awesome.min.css";
import estados_pagamento from "../../misc/estados_pagamento";

import "./styles.scss";
import cogoToast from "cogo-toast";

moment.locale("pt");

const acoesPagamento = (_, pagamento, reload) => {
    let Content = () => {
        const formulario = useFormulario();

        if (pagamento.estado === "em_aberto") {
            return <>
                <a className="link link_acao"
                   onClick={() => {
                       formulario.abrirFormulario("editar_pagamento", {
                           extra: {
                               id: pagamento.id
                           },
                           valoresIniciais: {
                               valor_premio: pagamento.valor_premio.toFixed(2),
                               valor_custos_operacionais: pagamento.valor_custos_operacionais.toFixed(2),
                               valor_despesas_administrativas: pagamento.valor_despesas_administrativas.toFixed(2),
                           },
                           formularioSubmetido: reload
                       })
                   }
                   }>Editar despesas/prémios</a><br/>
                <a className="link link_acao" onClick={async () => {
                    confirmAlert({
                        title: `Confirmação`,
                        message: "Pretende passar o pagamento de " + pagamento.utilizador_nome + " para o estado 'Aguarda Recibo'?",
                        buttons: [
                            {
                                label: 'Sim',
                                onClick: async () => {
                                    await post(`/financas/pagamentos/${pagamento.id}/marcar_aguarda_recibo`);
                                    cogoToast.success('Pagamento está agora pendente de recibo', {hideAfter: 5, position: 'top-right'});
                                    reload();
                                }
                            },
                            {label: 'Cancelar'}
                        ]
                    });
                }
                }>Fechar</a>
            </>
        }
        if (pagamento.estado === "aguarda_recibo") {
            return <>
                <a className="link link_acao" onClick={async () => {
                    confirmAlert({
                        title: `Confirmação`,
                        message: "Pretende passar o pagamento de " + pagamento.utilizador_nome + " para o estado 'Em pagamento'?",
                        buttons: [
                            {
                                label: 'Sim',
                                onClick: async () => {
                                    await post(`/financas/pagamentos/${pagamento.id}/marcar_pendente`);
                                    cogoToast.success('Estado alterado para "em pagamento"', {hideAfter: 5, position: 'top-right'});
                                    reload();
                                }
                            },
                            {label: 'Cancelar'}
                        ]
                    });

                }
                }>Passar a pagamento</a>
            </>
        }
        if (pagamento.estado === "pendente") {
            return <>
                <a className="link link_acao" onClick={async () => {
                    confirmAlert({
                        title: `Confirmação`,
                        message: "Pretende marcar o pagamento de " + pagamento.utilizador_nome + " como 'Pago'?",
                        buttons: [
                            {
                                label: 'Sim',
                                onClick: async () => {
                                    await post(`/financas/pagamentos/${pagamento.id}/marcar_pago`);
                                    cogoToast.success('Pagamento finalizado', {hideAfter: 5, position: 'top-right'});
                                    reload();
                                }
                            },
                            {label: 'Cancelar'}
                        ]
                    });
                }
                }>Marcar como pago</a><br/>
                <a className="link link_acao" onClick={async () => {
                    confirmAlert({
                        title: `Confirmação`,
                        message: "Pretende anular o estado 'Em pagamento'?",
                        buttons: [
                            {
                                label: 'Sim',
                                onClick: async () => {
                                    await post(`/financas/pagamentos/${pagamento.id}/aguarda_recibo`);
                                    cogoToast.success('Estado alterado para "aguarda recibo"', {hideAfter: 5, position: 'top-right'});
                                    reload();
                                }
                            },
                            {label: 'Cancelar'}
                        ]
                    });
                }
                }>Anular</a>
            </>
        }
        if (pagamento.estado == "pendente") {
            return <>
                <a className="link link_acao">Marcar como pago</a><br/>
                <a className="link link_acao">Anular</a>
            </>
        }
        return null;
    }
    return <Content/>;
}

const Comissoes = () => {
    const formulario = useFormulario();
    const history = useHistory();
    let columns = [
        {
            dataField: "data_inicio",
            text: 'Mês',
            formatter(data) {
                return moment(data).format("MMMM, YYYY");
            }
        }, {
            dataField: 'fornecedor',
            text: 'Fornecedor',
            formatter: formatarDinheiro
        }, {
            dataField: 'comissoes',
            text: 'Comissões',
            formatter: formatarDinheiro
        }, {
            dataField: 'premios',
            text: 'Prémios',
            formatter: formatarDinheiro
        }, {
            text: 'Despesas',
            formatter(_, mes) {
                return <>
                    C.O: {Intl.NumberFormat('pt', {style: 'currency', currency: 'EUR'}).format(mes.co)}
                    <br/>
                    D.A: {Intl.NumberFormat('pt', {style: 'currency', currency: 'EUR'}).format(mes.da)}
                </>
            }
        }, {
            dataField: 'total_a_pagar',
            text: 'Total a pagar',
            formatter: formatarDinheiro
        }, {
            dataField: 'data_fechado',
            text: 'Estado',
            formatter(data_fechado) {
                return data_fechado ? <span style={{textTransform: "uppercase", backgroundColor: "#675B4F40", color: "white", borderRadius: "7px", padding: "8px"}}>Fechado</span> : <span style={{textTransform: "uppercase", backgroundColor: "#4F8B1C40", color: "white", borderRadius: "7px", padding: "8px"}}>Aberto</span>;
            }
        }, {
            dataField: 'action',
            text: 'Ações',
            formatter: (_, mes, reload) => {
                if (!mes.data_fechado) {
                    let Content = () => {
                        const [esconderAcoes, setEsconderAcoes] = useState(false);

                        if (esconderAcoes) {
                            return <i className={"fa fa-spinner rotate"} style={{fontSize: 20}}/>
                        }

                        return <div>
                            <a className="link link_acao"
                               onClick={() => {
                                   formulario.abrirFormulario("importar_report", {
                                       valoresIniciais: {
                                           mes: mes.id
                                       },
                                       formularioSubmetido: async () => {
                                           reload();
                                       }
                                   })
                               }}>Importar report <span style={{fontSize: "8px", textDecoration: "none"}}>(último: {mes.ultima_importacao ? moment(mes.ultima_importacao).format("DD/MM/YYYY") : "nunca"})</span></a><br/>
                            <a className="link link_acao"
                               onClick={() => {
                                   confirmAlert({
                                       title: `Confirmação`,
                                       message: `Pretende fechar o mês ${moment(mes.data_inicio).format("MMMM, YYYY")}? Os pagamentos aos colaboradores serão calculados e não será possível importar mais movimentos para este mês.`,
                                       buttons: [
                                           {
                                               label: 'Sim',
                                               onClick: async () => {
                                                   setEsconderAcoes(true);
                                                   cogoToast.warn('Esta ação pode demorar alguns segundos, por favor aguarde.', {hideAfter: 5, position: 'top-right'});
                                                   try {
                                                       await post("/financas/meses/" + mes.id + "/fechar");
                                                       cogoToast.success('Mês fechado com sucesso', {hideAfter: 5, position: 'top-right'});
                                                       reload();
                                                   } catch (e) {
                                                   }
                                                   setEsconderAcoes(false);
                                               }
                                           },
                                           {label: 'Cancelar'}
                                       ]
                                   });
                               }}>Fechar mês</a>
                        </div>;
                    }
                    return <Content/>;
                }
            }
        }
    ];

    return <TabelaPaginada
        numero_resultados={6}
        dataUrl={"/financas/meses/listar"}
        columns={columns}
        title={"Comissões"}
        mostrarExportacao
    />
}

const Pagamentos = () => {
    const formulario = useFormulario();
    const history = useHistory();
    let columns = [
        {
            dataField: "utilizador_nome",
            text: 'Colaborador',
            formatter: (_, {utilizador_nome, utilizador_id}) => <Link className="link" to={"/colaborador/" + utilizador_id}>{utilizador_nome}</Link>
        }, {
            dataField: 'data_criacao',
            text: 'Data',
            formatter: (data) => moment(data).format("DD / MM / YYYY")
        }, {
            dataField: 'valor_a_pagar',
            text: 'Valor',
            formatter: formatarDinheiro
        }, {
            dataField: 'estado',
            text: 'Estado',
            formatter(estado) {
                return <span style={{textTransform: "uppercase", whiteSpace: "nowrap", backgroundColor: estados_pagamento[estado].cor, color: "white", borderRadius: "7px", padding: "8px"}}>{estados_pagamento[estado].designacao}</span>
            }
        }, {
            dataField: 'action',
            text: 'Ações',
            formatter: acoesPagamento
        }
    ];

    let ano_atual = new Date().getFullYear();
    let ano_inicio = 2014;
    let filters = [{
        label: "Ano",
        name: "ano",
        type: "select",
        default: ano_atual,
        options: new Array(ano_atual - ano_inicio + 1).fill(0)
            .map((_, idx) => ({value: ano_atual - idx, label: ano_atual - idx}))
    }]

    return <div className={"mt-3"}>
        <TabelaPaginada
            numero_resultados={12}
            dataUrl={"/financas/pagamentos/listar"}
            columns={columns}
            filters={filters}
            title={"Pagamentos a Colaboradores"}
            mostrarExportacao
            filtro={{
                estado: "em_aberto,aguarda_recibo,pendente,pago,caducado,anulado"
            }}
        />
    </div>
}

const GestaoFinanceira = () => {
    return <div className={"GestaoFinanceira"}>
        <Comissoes/>
        <Pagamentos/>
        <div className={"card mt-3"}>
            <div className="card-padding">
                <p className={"font-weight-bold table-title primary-color mr-3 flex-grow-1"}>Operações</p>
                <a style={{display: "flex"}} href={process.env.REACT_APP_API_URL + "/financas/relatorios/confrontacao"} target={"_blank"}>
                    <p className={"table-sub-title primary-color mt-3"}><i className={"fa fa-file-excel-o mr-3"}/>Relatório de Confrontação de Créditos Acumulados vs Valores Pagos</p>
                </a>
            </div>
        </div>
    </div>
};

export default GestaoFinanceira;
export {acoesPagamento}