import React, {useCallback, useEffect, useState} from 'react';
import moment from "moment";
import "moment/locale/pt";
import {get, post} from "../../YoubizProvider";
import {useForm} from "react-hook-form";

import "./styles.scss";
import cogoToast from "cogo-toast";

moment.locale("pt");

const GestaoDeNegocio = () => {
    const {register, reset, handleSubmit} = useForm();
    const [configuracao, setConfiguracao] = useState(null);
    const [submetendo, setSubmetendo] = useState(false);

    useEffect(() => {
        get("/negocio/configuracao/obter").then((d) => {
            setConfiguracao(d);
            reset(d);
        });
    }, []);

    const submeter = useCallback(async (dados) => {
        setSubmetendo(true);
        try {
            cogoToast.warn('Esta ação pode demorar alguns segundos, por favor aguarde.', {hideAfter: 5, position: 'top-right'});
            await post(`/negocio/configuracao/editar`, dados);
            cogoToast.success('Negócio editado com sucesso', {hideAfter: 5, position: 'top-right'});
        } catch (e) {
            cogoToast.error(e.response?.data?.message || e.message, {hideAfter: 5, position: 'top-right'});
        }
        setSubmetendo(false);
    }, []);

    if (!configuracao)
        return null;

    return <div className={"GestaoDeNegocio"}>
        <div className={"card mb-3"}>
            <div className="card-padding">
                <p className={"font-weight-bold table-title primary-color mr-3 flex-grow-1"}>Gestão de Negócio</p>
                <p className={"font-weight-bold table-sub-title primary-color mr-3 flex-grow-1"}>Dados Gerais</p>
                <div className="form-group">
                    <label>NIB
                        <input ref={register} name={"nib"} type="text" className="form-control" placeholder="0000 0000 00000000000 00"/>
                    </label>
                </div>

                {configuracao.patamares?.map((p, idx) => {
                    if (p.id === 0) return null;
                    return <>
                        <input type={"hidden"} ref={register} name={`patamares[${idx}].id`}/>
                        <p className={"font-weight-bold table-sub-title primary-color mr-3 flex-grow-1"}>Configuração {p.designacao}</p>
                        <div className="form-group">
                            <label>Créditos para atingir o patamar
                                <input ref={register} name={`patamares[${idx}].limiar_atribuicao`} type="number" className="form-control" placeholder="1"/>
                            </label>
                        </div>
                        <div className="form-group">
                            <label>Comissão sobre carregamentos diretos
                                <input ref={register} name={`patamares[${idx}].comissoes[0]`} type="number" step="0.1" className="form-control" placeholder="10%"/>
                            </label>
                        </div>
                        <div className="form-group">
                            <label>Comissão sobre carregamentos de 2ª linha
                                <input ref={register} name={`patamares[${idx}].comissoes[1]`} type="number" step="0.1" className="form-control" placeholder="0%"/>
                            </label>
                        </div>
                        <div className="form-group">
                            <label>Comissão sobre carregamentos de 3ª linha
                                <input ref={register} name={`patamares[${idx}].comissoes[2]`} type="number" step="0.1" className="form-control" placeholder="0%"/>
                            </label>
                        </div>
                    </>;
                })}

                {/*<p className={"font-weight-bold table-sub-title primary-color mr-3 flex-grow-1"}>Limite de SIMs</p>
                <div className="form-group">
                    <label>Limite de SIMs para taxas de carregamento até 10%
                        <input type="text" className="form-control" placeholder="30"/>
                    </label>
                </div>

                <div className="form-group">
                    <label>Limite de SIMs para taxas de carregamento entre 11% e 29%
                        <input type="text" className="form-control" placeholder="120"/>
                    </label>
                </div>*/}

                <p className={"font-weight-bold table-sub-title primary-color mr-3 flex-grow-1"}>Impostos e Despesas</p>
                <div className="form-group">
                    <label>Taxa de IVA a retirar dos relatórios
                        <input ref={register} name={"taxa_iva_relatorio"} type="text" className="form-control" placeholder="23%"/>
                    </label>
                </div>
                <div className="form-group">
                    <label>Valor base de despesas de administração
                        <input ref={register} name={"base_despesas_administracao"} type="text" className="form-control" placeholder="4€"/>
                    </label>
                </div>
                <button disabled={submetendo} type="submit" className={"btn btn-s px-4 btn-primary"} onClick={!submetendo && handleSubmit(submeter)}>Guardar</button>
            </div>
        </div>
    </div>
};

export default GestaoDeNegocio;