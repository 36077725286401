import React, {useEffect, useState} from 'react';
import {Button, Form, FormGroup} from 'react-bootstrap';
import {useForm} from "react-hook-form";
import {useYoubiz} from "../../YoubizProvider";
import cogoToast from "cogo-toast";

import './styles.scss'

import Visualizar from "../../assets/images/visualizar_icon.svg";
import Esconder from "../../assets/images/esconder_icon.svg";
import {useFormulario} from "../../components/Formularios/Provider";
import {Link, useLocation} from "react-router-dom";

const toastoptions = {
    hideAfter: 5,
    position: 'top-right'
}

const Login = () => {
    const {register, handleSubmit, setError, errors} = useForm();
    const [mostrarPassword, setMostrarPassword] = useState(false);
    const youbiz = useYoubiz();
    const formulario = useFormulario();
    const location = useLocation();

    useEffect(() => {
        let params = new URLSearchParams(location.search);
        let resetToken = params.get("reset");
        if (resetToken)
            formulario.abrirFormulario("recuperar_password", {
                extra: {
                    resetToken
                }
            })
    }, [location]);

    const onSubmit = async data => {
        await youbiz.login(data).then(() => {
            cogoToast.success('Fez login com sucesso.', toastoptions);
        }).catch(function (error) {
            if (error.response.data.code === 401) {
                setError("button", {
                    type: "server",
                    message: error.response.data.message
                });
            }
        });
    };

    return <div className={"Login"}>
        <h4 className={"mb-4 font-weight-bold text-center primary-color"}>É aqui que gere o seu negócio!</h4>
        <div className={"form"}>
            <Form noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                <FormGroup className={"mb-3"}>
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control name={"utilizador"} ref={register({
                        required: true
                    })}/>
                    {errors.email?.type === "required" && <p className={"error-message"}>Introduza o endereço de e-mail associado à sua conta</p>}
                </FormGroup>

                <Form.Group className={"m-0 password"} controlId={"password"}>
                    <Form.Label>Password</Form.Label>
                    <Form.Control name={"password"} type={mostrarPassword ? "text" : "password"} placeholder={"Password"} ref={register({
                        required: true
                    })}/>
                    <img src={mostrarPassword ? Esconder : Visualizar} className={"ver_password"} onClick={() => setMostrarPassword(!mostrarPassword)}/>
                    {errors.password && <p className={"error-message"}>A password é obrigatória</p>}
                </Form.Group>
                <div className={"mb-3 mt-3 text-center"}>
                    <a className={"primary-color underline"} href={"#"} onClick={e => {
                        e.preventDefault();
                        formulario.abrirFormulario("recuperar_password");
                    }}>Recuperar a senha</a>
                </div>
                <div className={"text-center mt-4"}>
                    <Button name={"button"} className={"pt-2 pb-2 btn-block"} variant="login" type={"submit"} ref={register}>
                        Entrar
                    </Button>
                    {errors.button?.type === "server" && <p className={"mb-0 error-message"}>{errors.button?.message}</p>}
                </div>
                <div className={"mb-3 mt-3 text-center"}>
                    <Link className={"text-gray"} style={{textDecoration: "underline"}} to={"/registo"}>Ainda não tem conta? Registe-se</Link>
                </div>
            </Form>
        </div>
    </div>
}

export default Login;