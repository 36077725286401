import React, {useEffect, useState} from "react";
import verificarNif from "../../../../functions/verificarNif";

import './styles.scss';
import DatePicker from "react-datepicker";
import {Controller, useFormContext} from "react-hook-form";
import {get} from "../../../../YoubizProvider";

export default ({values, register, errors, extra = {}}) => {
    const [mostrarPassword, setMostrarPassword] = useState(false);
    const {control} = useFormContext();
    const [configuracao, setConfiguracao] = useState(null);

    useEffect(() => {
        get("/negocio/configuracao/obter").then((d) => {
            setConfiguracao(d);
        });
    }, []);

    return <div className={"NovoClientePopup"}>

        <div className="modal-subtitle pb-3">Dados gerais</div>

        <div className={"row"}>
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.email?.message}>
                        Email
                        <input ref={register({
                            required: "Por favor indique o email",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "O email não é válido"
                            }
                        })} name="email" placeholder="email@youbiz.pt" type="email" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.nome?.message}>
                        Nome Completo
                        <input ref={register({
                            required: "Por favor introduza o nome completo do utilizador"
                        })} name="nome" placeholder="Nome" type="text" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.telemovel?.message}>
                        Nº Contacto
                        <input ref={register({
                            required: "Por favor indique o número de telemóvel do utilizador",
                        })} name="telemovel" placeholder="910 000 000" type="text" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.data_nascimento?.message}>
                        Data de Nascimento
                        <Controller
                            control={control}
                            name="data_nascimento"
                            defaultValue={null}
                            rules={{
                                //required: "Por favor indique a data de nascimento do utilizador",
                            }}
                            render={({onChange, value}) => (
                                <DatePicker dateFormat="dd/MM/yyyy" placeholderText={"01/01/2000"} selected={value} onChange={onChange} className="form-control form-control-sm"/>
                            )}
                        />
                    </label>
                </div>
            </div>
        </div>

        <div className="modal-subtitle py-3">Morada</div>

        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.morada?.message}>
                        Morada
                        <input ref={register({
                            //required: "Por favor introduza a morada"
                        })} name="morada" placeholder="Rua do Ouro" type="text" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-4">
                <div className="form-group">
                    <label data-error={errors.codigo_postal?.message}>
                        Código Postal
                        <input ref={register({
                            //required: "Por favor introduza o código postal do utilizador"
                        })} name="codigo_postal" placeholder="3641-331" type="text" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-group">
                    <label data-error={errors.localidade?.message}>
                        Localidade
                        <input ref={register({
                            //required: "Por favor introduza a localidade da morada"
                        })} name="localidade" placeholder="Localidade" type="text" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>

        <div className="modal-subtitle py-3">Documentos de Identificação</div>

        <div className="row">
            <div className="col-md-4">
                <div className="form-group">
                    <select
                        ref={register({
                            //required: "Escoha o tipo de documento de identificação"
                        })}
                        name={"doc_identificacao_tipo"}
                        className={"form-control form-control-sm ok"}>
                        <option disabled selected value={""}>Tipo de Documento</option>
                        <option value={"BI"}>Bilhete de Identidade</option>
                        <option value={"CC"}>Cartão do Cidadão</option>
                        <option value={"PASSPORT"}>Passaporte</option>
                    </select>
                </div>
            </div>
            <div className="col-md-8">
                <div className="form-group">
                    <label data-error={errors.doc_identificacao_numero?.message}>
                        <input ref={register({
                            //required: "Por favor introduza o número do cartão de identificação"
                        })} name="doc_identificacao_numero" placeholder="Número do Documento" type="text" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-4">
                <div className="form-group">
                    <label data-error={errors.doc_identificacao_emissao?.message}>
                        Data de Emissão
                        <Controller
                            control={control}
                            name="doc_identificacao_emissao"
                            defaultValue={null}
                            rules={{
                                //required: "Por favor indique a data de emissão do documento de identificação",
                            }}
                            render={({onChange, value}) => (
                                <DatePicker dateFormat="dd/MM/yyyy" placeholderText={"01/01/2000"} selected={value} onChange={onChange} className="form-control form-control-sm"/>
                            )}
                        />
                    </label>
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-group">
                    <label data-error={errors.doc_identificacao_validade?.message}>
                        Data de Validade
                        <Controller
                            control={control}
                            name="doc_identificacao_validade"
                            defaultValue={null}
                            rules={{
                                //required: "Por favor indique a data de validade do documento de identificação",
                            }}
                            render={({onChange, value}) => (
                                <DatePicker dateFormat="dd/MM/yyyy" placeholderText={"01/01/2000"} selected={value} onChange={onChange} className="form-control form-control-sm"/>
                            )}
                        />
                    </label>
                </div>
            </div>
            <div className="col-md-4">
                <div className="form-group">
                    <label data-error={errors.doc_identificacao_local_emissao?.message}>
                        Local de Emissão
                        <input ref={register({
                            //required: "Por favor introduza o local de emissão do documento de identificação"
                        })} name="doc_identificacao_local_emissao" placeholder="Local" type="text" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>

        <div className="modal-subtitle py-3">Dados de Faturação</div>

        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.nif?.message}>
                        Número de Identificação Fiscal (NIF)
                        <input ref={register({
                            //required: "Por favor introduza o número de contribuinte do utilizador",
                            validate: (valor) => {
                                if (!valor || verificarNif(valor))
                                    return true;
                                return "O número de contribuinte não é válido"
                            }
                        })} name="nif" placeholder="000 000 000" type="text" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.iban?.message}>
                        IBAN
                        <input ref={register({
                            //required: "Por favor introduza o IBAN do utilizador"
                        })} name="iban" placeholder="PT50 0035 0000 0000 0000 0000 0" type="text" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>

        {extra.acao === 'novo_colaborador' && <>
            <div className="modal-subtitle py-3">Dados Youbiz</div>

            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label data-error={errors.numero_telemovel_youbiz?.message}>
                            Número de telemóvel Youbiz
                            <input ref={register({
                                //required: "Por favor introduza número de telemóvel Youbiz",
                                validate: value => {
                                    if (value.trim().length > 0 && (value.length !== 9 || value.replace(/[^0-9.]/g, '').length !== 9))
                                        return "Introduza apenas 9 números"
                                    return true
                                }
                            })} name="numero_telemovel_youbiz" placeholder="930 000 000" type="text" className="form-control form-control-sm"/>
                        </label>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label data-error={errors.numero_ascendente?.message}>
                            Número do Ascendente (será ignorado caso o telemóvel Youbiz esteja associado a outro utilizador)
                            <input ref={register({
                                //required: "Por favor introduza o número de telemóvel do ascendente do utilizador",
                                validate: value => {
                                    if (value.trim().length > 0 && (value.length !== 9 || value.replace(/[^0-9.]/g, '').length !== 9))
                                        return "Introduza apenas 9 números"
                                    return true
                                }
                            })} name="numero_ascendente" placeholder="930 000 000" type="text" className="form-control form-control-sm"/>
                        </label>
                    </div>
                </div>
            </div>
        </>}

        <div className="modal-subtitle py-3">Regras de Negócio</div>

        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    {configuracao && <label data-error={errors.nif?.message}>
                        Patamar
                        <select
                            ref={register}
                            name={"patamar"}
                            className={"form-control form-control-sm ok"}>
                            {configuracao.patamares.map(p => <option value={p.id}>{p.designacao} - {p.comissoes.map(c => c + "%").join(", ")}</option>)}
                        </select>
                    </label>}
                </div>
                <div className="form-group">
                    <div className="form-check">
                        <label className="form-check-label cursor-pointer">
                            <input className="form-check-input" type="radio" name="comissoes_personalizadas" value={"false"} ref={register}/>
                            <i className="input-helper"></i>
                            Aplicar regras por defeito
                        </label>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label cursor-pointer">
                            <input className="form-check-input" type="radio" name="comissoes_personalizadas" value={"true"} ref={register}/>
                            <i className="input-helper"></i>
                            Aplicar regras próprias para o utilizador
                        </label>
                    </div>
                </div>
                <div style={{marginLeft: "20px", display: values.comissoes_personalizadas === "true" ? undefined : "none"}}>
                    <div className="form-group">
                        <label data-error={errors.comissoes?.[0]?.message}>
                            Comissão sobre carregamentos diretos
                            <input ref={register({
                                required: values.comissoes_personalizadas === "true" ? "Por favor introduza a taxa de comissão para carregamentos diretos" : false
                            })} name="comissoes[0]" placeholder="10%" type="number" step="0.01" className="form-control form-control-sm"/>
                        </label>
                    </div>
                    <div className="form-group">
                        <label data-error={errors.comissoes?.[1]?.message}>
                            Comissão sobre carregamentos de 2ª linha
                            <input ref={register({
                                required: values.comissoes_personalizadas === "true" ? "Por favor introduza a taxa de comissão para carregamentos de 2ª linha" : false
                            })} name="comissoes[1]" placeholder="0%" type="number" step="0.01" className="form-control form-control-sm"/>
                        </label>
                    </div>
                    <div className="form-group">
                        <label data-error={errors.comissoes?.[2]?.message}>
                            Comissão sobre carregamentos de 3ª linha
                            <input ref={register({
                                required: values.comissoes_personalizadas === "true" ? "Por favor introduza a taxa de comissão para carregamentos de 3ª linha" : false
                            })} name="comissoes[2]" placeholder="0%" type="number" step="0.01" className="form-control form-control-sm"/>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal-subtitle py-3">Predefinições dos pagamentos</div>

        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.despesas_administrativas_mensais?.message}>
                        Despesas administrativas mensais
                        <input ref={register} name="despesas_administrativas_mensais" placeholder={"Valor defeito (" + configuracao?.base_despesas_administracao + "€)"} type="number" step="0.01" className="form-control form-control-sm"/>
                    </label>
                </div>
                <div className="form-group">
                    <label data-error={errors.premio_mensal?.message}>
                        Prémio mensal
                        <input ref={register} name="premio_mensal" placeholder={"0.00"} type="number" step="0.01" className="form-control form-control-sm"/>
                    </label>
                </div>
            </div>
        </div>

        {/*<div className="modal-subtitle py-3">Alterar Password</div>

        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label data-error={errors.password_atual?.message || errors.password_nova?.message} className={"w-100"}>
                        <div className="row">
                            <div className="col-md-6">
                                <label className={"password"}>
                                    <img src={mostrarPassword ? Esconder : Visualizar} className={"ver_password"} onClick={() => setMostrarPassword(!mostrarPassword)}/>
                                    <input ref={register({
                                        validate: (valor) => valor && !values.password_nova ? "Para alterar a password indique a password nova" : true
                                    })} name="password_atual" placeholder="Password Atual" type={mostrarPassword ? "text" : "password"} className="form-control form-control-sm"/>
                                </label>
                            </div>
                            <div className="col-md-6">
                                <label className={"password"}>
                                    <img src={mostrarPassword ? Esconder : Visualizar} className={"ver_password"} onClick={() => setMostrarPassword(!mostrarPassword)}/>
                                    <input ref={register({
                                        validate: (valor) => valor && !values.password_atual ? "Para alterar a password indique a password atual" : true
                                    })} name="password_nova" placeholder="Nova password" type={mostrarPassword ? "text" : "password"} className="form-control form-control-sm"/>
                                </label>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        </div>*/}

        <div className="modal-subtitle py-4">Notificações</div>

        <div className="row">
            <div className="col-md-12">
                <div className="form-check">
                    <label className="form-check-label label-checkbox">
                        <input name="notificacao_sms" type="checkbox" className="form-check-input" ref={register}/>
                        <i className="input-helper"></i>
                        Recebe atualizações de negócio via SMS
                    </label>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="form-check mt-2">
                    <label className="form-check-label label-checkbox">
                        <input name="notificacao_email" type="checkbox" className="form-check-input" ref={register}/>
                        <i className="input-helper"></i>
                        Recebe atualizações de negócio via Email
                    </label>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-12">
                <div className="form-check mt-2">
                    <label className="form-check-label label-checkbox">
                        <input name="marketing" type="checkbox" className="form-check-input" ref={register}/>
                        <i className="input-helper"></i>
                        Aceita os termos de marketing
                    </label>
                </div>
            </div>
        </div>

        <div className="modal-subtitle py-4">Outros</div>
        <div className="row">
            <div className="col-md-12">
                <div className="form-check mt-2">
                    <label className="form-check-label label-checkbox">
                        <input name="ativo" type="checkbox" className="form-check-input" ref={register}/>
                        <i className="input-helper"></i>
                        Ativo
                    </label>
                </div>
            </div>
        </div>
        {/*<div className="row">
            <div className="col-md-12">
                <div className="form-check mt-2">
                    <label className="form-check-label label-checkbox">
                        <input name="rankings" type="checkbox" className="form-check-input" ref={register}/>
                        <i className="input-helper"></i>
                        Considerado nos rankings
                    </label>
                </div>
            </div>
        </div>*/}
    </div>;

}