import React, {useEffect} from 'react';

import './styles.scss';
import IconMorada from "../../assets/images/morada.svg";
import IconEmail from "../../assets/images/email.svg";
import IconTel from "../../assets/images/tel.svg";
import IconLink from "../../assets/images/link.svg";
import WhatsApp from "../../assets/images/whatsapp.svg";
import {useFormulario} from "../../components/Formularios/Provider";
import {useHistory} from "react-router-dom";
import {get, post, useYoubiz} from "../../YoubizProvider";
import {confirmAlert} from "react-confirm-alert";
import cogoToast from "cogo-toast";

const Contactos = () => {
    const [linksUteis, setLinksUteis] = React.useState([]);
    const formulario = useFormulario();
    const history = useHistory();
    const youbiz = useYoubiz();

    useEffect(() => {
        get("/negocio/links_uteis/listar").then(setLinksUteis);
    }, [])

    return <div className={"Contactos"}>
        <div className={"card mb-3"}>
            <div className="card-padding">
                <p className={"font-weight-bold table-title primary-color mr-3 flex-grow-1"}>Contactos</p>
                <p className={"table-sub-title primary-color mr-3 flex-grow-1"}>Descobre a melhor forma de nos contactares!</p>

                <div className="row my-5">
                    <div className="col-md-4 text-center">
                        <img src={IconMorada} alt={""} className={"mt-5"}/>
                        <p className={"font-weight-bold table-sub-title primary-color flex-grow-1 mt-3"}>YouBIZ, LDA.</p>
                        <p className={"primary-color flex-grow-1"}>Doca de Alcântara,<br/>Armazém 3 1300-299 Lisboa Portugal</p>
                    </div>
                    <div className="col-md-4 text-center">
                        <img src={IconEmail} alt={""} className={"mt-5"}/>
                        <p className={"font-weight-bold table-sub-title primary-color flex-grow-1 mt-3"}>Email</p>
                        <p className={"primary-color flex-grow-1"}>geral@youbiz.pt<br/>www.youbiz.pt</p>
                    </div>
                    <div className="col-md-4 text-center">
                        <img src={IconTel} alt={""} className={"mt-5"}/>
                        <p className={"font-weight-bold table-sub-title primary-color flex-grow-1 mt-3"}>Tel</p>
                        <p className={"primary-color flex-grow-1"}>93 636 23 23</p>
                        <small id="emailHelp" className="form-text text-muted mb-3">Disponível das 09:00 às 13:00 e das 14:00 às 17:00, de Segunda a Sexta-feira, exceto feriados.</small>
                        <a className={"primary-color flex-grow-1"} href={"https://wa.me/message/T4WFUJZQS4LJO1"}>Ou contacta-nos via Whatsapp<img src={WhatsApp} alt={""} style={{width: "35px", marginLeft: "5px"}}/></a>
                    </div>
                </div>

            </div>
        </div>

        {youbiz.utilizador && <>
            <div className={"card mb-3"}>
                <div className="card-padding">
                    <p className={"font-weight-bold table-title primary-color mr-3 flex-grow-1"}>Links úteis</p>
                    <p className={"table-sub-title primary-color mr-3 flex-grow-1"}>Mais informações sobre a Youbiz</p>

                    {linksUteis.map(link => <a style={{display: "flex", opacity: link.visivel ? 1 : 0.5}} href={link.url} target={"_blank"}>
                        <img src={IconLink} alt={""} className={"mr-2"}/>
                        <p className={"table-sub-title primary-color flex-grow-1 mt-3"}>{link.titulo}{link.visivel ? "" : " (escondido)"}</p>
                        {youbiz.utilizador.tipo === "admin" && <>
                            <a className={"links-uteis"}
                               onClick={(e) => {
                                   e.preventDefault();
                                   formulario.abrirFormulario("link_util", {
                                       extra: {
                                           id_link: link.id
                                       },
                                       valoresIniciais: link,
                                       formularioSubmetido: async () => {
                                           get("/negocio/links_uteis/listar").then(setLinksUteis);
                                       },
                                       titulo: "Editar Link Útil"
                                   })
                               }}
                            >Editar</a>
                            <a className={"links-uteis"} onClick={e => {
                                e.preventDefault();
                                confirmAlert({
                                    title: `Confirmação`,
                                    message: "Pretende apagar o link útil '" + link.titulo + "'?",
                                    buttons: [
                                        {
                                            label: 'Sim',
                                            onClick: async () => {
                                                await post(`/negocio/links_uteis/${link.id}/apagar`);
                                                get("/negocio/links_uteis/listar").then(setLinksUteis);
                                                cogoToast.success('Link removido', {hideAfter: 5, position: 'top-right'});
                                            }
                                        },
                                        {label: 'Cancelar'}
                                    ]
                                });
                            }}>Eliminar</a>
                        </>}
                    </a>)}

                    {youbiz.utilizador.tipo === "admin" &&
                        <a className={"links-uteis"}
                           onClick={() => {
                               formulario.abrirFormulario("link_util", {
                                   valoresIniciais: {
                                       visivel: true
                                   },
                                   formularioSubmetido: async () => {
                                       get("/negocio/links_uteis/listar").then(setLinksUteis);
                                   }
                               })
                           }}
                        >Adicionar novo</a>}
                </div>
            </div>
        </>}
    </div>;
};

export default Contactos;