import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";

export default ({values, register, errors, setValue, extra = {}}) => {
    let inputs;

    if (!extra.resetToken) {
        inputs = <>
            <label data-error={errors.email?.message}>
                <input name="email" placeholder={"E-mail"} type="email" className="form-control form-control-sm" ref={register({
                    required: "Por favor introduza o seu e-mail"
                })}/>
            </label>
        </>;
    } else {
        inputs = <>
            <input type="hidden" name={"token_acesso"} value={extra.resetToken} ref={register}/>
            <label data-error={errors.password?.message}>
                <input name="password" placeholder={"Password Nova"} type="password" className="form-control form-control-sm" ref={register({
                    required: "Especifique a password nova"
                })}/>
            </label>
            <label data-error={errors.password_confirmacao?.message}>
                <input name="password_confirmacao" placeholder={"Confirmação"} type="password" className="form-control form-control-sm" ref={register({
                    required: "Repita a password que quer definir",
                    validate: (value) => {
                        if (value !== values.password)
                            return "As passwords não coincidem";
                        return true;
                    }
                })}/>
            </label>
        </>;
    }
    return <div className={"RecuperarPassword"}>
        {!extra.resetToken && <p className={"mb-4"}>Introduza o seu e-mail. Caso o registo seja encontrado iremos enviar uma notificação por e-mail.</p>}
        {extra.resetToken && <p className={"mb-4"}>Para completar a recuperação de acesso, indique a password nova que pretende utilizar</p>}
        <div className={"form-group"}>
            {inputs}
        </div>
        <label data-error={errors.captcha?.message}>
            <input type="hidden" name={"captcha"} ref={register({
                required: "Clique na caixa de verificação"
            })}/>
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={result => {
                    setValue("captcha", result);
                }}
            />
        </label>
    </div>
}