import React from 'react';
import './App.scss';
import Navbar from './template/shared/Navbar';
import Footer from "./components/Footer";
import Routes from "./Routes";
import YoubizProvider from "./YoubizProvider";
import FormularioProvider from "./components/Formularios/Provider";

const App = () => {
    return <YoubizProvider>
        <FormularioProvider>
            <div className="container-scroller">
                <Navbar/>
                <div className="container-fluid page-body-wrapper full-page-wrapper">
                    <div className="main-panel">
                        <Routes/>
                    </div>
                </div>
                <Footer/>
            </div>
        </FormularioProvider>
    </YoubizProvider>;
};

export default App;
