import {post} from "../../../../YoubizProvider";
import cogoToast from "cogo-toast";

const etapas = [
    {
        titulo: "",
        formulario: require("./0").default
    }
];

const toastoptions = {
    hideAfter: 5,
    position: 'top-right'
}

const submeter = async (valores, opcoes) => {
    valores.comissoes_personalizadas = valores.comissoes_personalizadas === "true";
    if (!valores.comissoes_personalizadas)
        delete valores.comissoes;

    valores.doc_identificacao_tipo = valores.doc_identificacao_tipo || null;

    await post("/utilizador/" + opcoes.extra.id_utilizador + "/editar", valores);
    cogoToast.success('Colaborador adicionado com sucesso', toastoptions);
};

const NovoColaborador = {
    titulo: "Editar Colaborador",
    submeter,
    etapas
};

export default NovoColaborador;