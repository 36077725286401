import {post} from "../../../../YoubizProvider";
import {serialize} from "object-to-formdata";
import cogoToast from "cogo-toast";

const etapas = [
    {
        titulo: "1. Dados do Report",
        formulario: require('./0').default
    },
    {
        titulo: "2. Resumo",
        formulario: require('./1').default,
        labelSeguinte: "Submeter"
    },
    {
        titulo: "3. Importação",
        formulario: require('./2').default,
        botaoAnterior: false,
        labelSumbeter: "Concluir"
    }
];

const toastoptions = {
    hideAfter: 5,
    position: 'top-right'
}

const submeter = async (valores) => {
};

const ImportarReport = {
    titulo: "Importar Report",
    submeter,
    etapas,
    podeNavegar: ({resultado_validacao, etapa_atual, etapa_proxima, valores}) => {
        if (etapa_atual === 2)
            return false;
    },
    aoNavegar: async ({etapa_atual, valores, setValue}) => {
        if (etapa_atual === 2) {
            let formData = serialize({
                mes: valores.mes,
                relatorio: valores.relatorio[0]
            }, {indices: true});
            try {
                let resultado = await post("/financas/importar_report", formData);
                setValue("resultado", JSON.stringify(resultado));
            } catch (e) {
                cogoToast.error('Ocorreu um erro ao importar o relatório: ' + (e.response?.data?.message || e.message), {hideAfter: 5, position: 'top-right'})
            }
        }
    }
};

export default ImportarReport;