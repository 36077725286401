import React, {useEffect, useState} from 'react';
import moment from "moment";
import "moment/locale/pt";
import {get, useYoubiz} from "../../YoubizProvider";
import {useFormulario} from "../../components/Formularios/Provider";
import {Link, useHistory} from "react-router-dom";

import formatarDinheiro from "../../functions/formatar_dinheiro";
import {Line} from "react-chartjs-2";
import AdicionarReport from "../../assets/images/adicionar_report.svg";
import Lupa from "../../assets/images/lupa.svg";

import "./styles.scss";
import TabelaPaginada from "../../components/TabelaPaginada";
import {OverlayTrigger, Tooltip} from "react-bootstrap";


moment.locale("pt");

const Grafico = ({dados}) => {
    return <div className="graph-wrapper m-4 mt-auto">
        <Line data={{
            labels: ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5", "Day 6", "Day 7"],
            datasets: [{
                label: 'Profit',
                data: [25, 20, 40, 30, 15, 10, 50],
                backgroundColor: [
                    "transparent",
                ],
                borderColor: [
                    "#b66dff",
                ],
                borderWidth: 2,
                fill: true
            }]
        }} options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    display: false,
                    ticks: {
                        beginAtZero: false
                    }
                }],
                xAxes: [{
                    display: false
                }]
            },
            legend: {
                display: false
            },
            elements: {
                point: {
                    radius: 0
                },
                line: {
                    tension: 0
                }
            },
            stepsize: 10
        }}/>
    </div>
}

const PesquisarColaborador = () => {
    const history = useHistory();
    const [pesquisa, setPesquisa] = useState("");

    function go() {
        history.push(`/comunidade/colaboradores?pesquisa=${pesquisa}`);
    }

    return <div className={"card pesquisar-colaborador rounded mb-3"}>
        <input className={"p-3"} type={"text"}
               placeholder={"Pesquisar Colaborador"}
               value={pesquisa}
               onChange={e => setPesquisa(e.target.value)}
               onKeyDown={(event) => {
                   if (event.key === 'Enter')
                       go();
               }}/>
        <img className={"p-3"} src={Lupa} onClick={go}/>
    </div>
}

const Colaboradores = () => {
    const [colaboradores, setColaboradores] = useState(null);

    useEffect(() => {
        get(`/negocio/dashboard/colaboradores`).then(setColaboradores);
    }, [])

    if (!colaboradores)
        return <div className={"card mb-3"}>
            <div className="card-padding">
                <p>A carregar informações</p>
            </div>
        </div>;

    return <div className={"card mb-3"}>
        <div className="card-padding">
            <h4 className={"font-weight-bold massive-title primary-color mr-3 flex-grow-1"} style={{display: "inline"}}>Colaboradores</h4>
            <p className={"mt-3"}>
                <span className={"destaque"}>{colaboradores.find(c => c.codigo_patamar === "CONSULTOR")?.contagem || 0}</span> consultores<br/>
                <span className={"destaque"}>{colaboradores.find(c => c.codigo_patamar === "EXECUTIVO")?.contagem || 0}</span> executivos<br/>
                <span className={"destaque"}>{colaboradores.find(c => c.codigo_patamar === "COORDENADOR")?.contagem || 0}</span> coordenadores<br/>
                Total de colaboradores: <strong>{colaboradores.reduce((acc, i) => acc + i.contagem, 0) || 0}</strong>
            </p>
        </div>
        <Grafico/>
    </div>;
}

const Clientes = () => {
    const [clientes, setClientes] = useState(null);

    useEffect(() => {
        get(`/negocio/dashboard/clientes`).then(setClientes);
    }, [])

    if (!clientes)
        return <div className={"card mb-3"}>
            <div className="card-padding">
                <p>A carregar informações</p>
            </div>
        </div>;

    return <div className={"card mb-3"}>
        <div className="card-padding">
            <h4 className={"font-weight-bold massive-title primary-color mr-3 flex-grow-1"} style={{display: "inline"}}>Clientes</h4>
            <p className={"mt-3"}>
                <span className={"destaque"}>{clientes.ano}</span> novos clientes este ano<br/>
                Total de clientes: <strong>{clientes.total}</strong>
            </p>
        </div>
        <Grafico/>
    </div>;
}

const Cartoes = () => {
    const [cartoes, setCartoes] = useState(null);

    useEffect(() => {
        get(`/negocio/dashboard/cartoes`).then(setCartoes);
    }, [])

    if (!cartoes)
        return <div className={"card mb-3"}>
            <div className="card-padding">
                <p>A carregar informações</p>
            </div>
        </div>;

    let diferenca_enviados = cartoes.enviados_ano_atual - cartoes.enviados_ano_anterior;
    let diferenca_pedidos = cartoes.pedidos_ano_atual - cartoes.pedidos_ano_anterior;

    return <div className={"card mb-3"}>
        <div className="card-padding">
            <h4 className={"font-weight-bold massive-title primary-color mr-3 flex-grow-1"} style={{display: "inline"}}>Cartões</h4>
            <p className={"mt-3"}>
                Cartões enviados em {moment().format("YYYY")}: <strong>{cartoes.enviados_ano_atual} </strong>
                <span className={diferenca_enviados > 0 ? "dif_positiva" : "dif_negativa"}>{diferenca_enviados > 0 ? "+" + diferenca_enviados : diferenca_enviados}</span>
                <br/>
                Colaboradores pediram cartões em {moment().format("YYYY")}: <strong>{cartoes.pedidos_ano_atual} </strong>
                <span className={diferenca_pedidos > 0 ? "dif_positiva" : "dif_negativa"}>{diferenca_pedidos > 0 ? "+" + diferenca_pedidos : diferenca_pedidos}</span>
            </p>
        </div>
        <Grafico/>
    </div>;
}

const Ranking = () => {
    const formulario = useFormulario();
    const youbiz = useYoubiz();
    const history = useHistory();
    const [paginacao, setPaginacao] = useState(false);
    let [produtos, setProdutos] = React.useState(null);

    useEffect(() => {
        get("/cartoes/produtos/listar?numero_resultados=5000").then(res => {
            let aux = {};
            res.resultados.forEach(produto => {
                aux[produto.id] = produto;
            });
            setProdutos(aux);
        })
    }, []);


    let columns = [
        {
            dataField: "posicao",
            text: '#',
        }, {
            dataField: "numero_colaborador",
            text: 'ID',
        }, {
            dataField: "utilizador_nome",
            text: 'Nome',
            formatter: (_, {nome, id}) => <Link className="link" to={"/colaborador/" + id}>{nome}</Link>
        }, {
            dataField: 'ativacoes',
            text: 'Novos clientes',
            formatter: (_, {ativacoes, produtos_ativados}) => <>
                <strong>{ativacoes}</strong> <OverlayTrigger
                overlay={<Tooltip>{produtos_ativados.map(p => <div className={"text-left"}><strong>{p.contagem}x</strong> {p.produto}<br/></div>)}</Tooltip>}>
                <i className={"fa fa-info-circle"}/>
            </OverlayTrigger>
            </>
        }, {
            text: 'Taxa de ativação',
            formatter: (_, {ativacoes, cartoes}) => <span>
                {Math.min(100, Math.round(ativacoes / cartoes * 100))}%
                <small> ({ativacoes || 0} de {cartoes || 0})</small>
            </span>
        }, {
            text: 'Último pedido',
            formatter(_, {pedido, data_ultimo_pedido}) {
                return <>
                    {moment(data_ultimo_pedido).format("DD/MM/YYYY")}
                    <div className={"mt-1"}>
                        {pedido.filter(p => p.quantidade > 0).map((p, idx) => <>{idx > 0 ? ", " : ""}<strong>{p.quantidade}x</strong> {produtos[p.id_produto]?.nome}</>)}
                    </div>
                </>
            }
        }, {
            dataField: 'action',
            text: 'Ações',
            formatter: (_, colaborador) => <a className="link link_acao"
                                              onClick={() => {
                                                  formulario.abrirFormulario("pedido_cartoes", {
                                                      extra: {
                                                          preencher_quantidades: youbiz.utilizador.tipo === "admin",
                                                          colaborador: colaborador
                                                      }
                                                  })
                                              }
                                              }>Pedir cartões</a>
        }
    ];

    let ano_atual = new Date().getFullYear();
    let ano_inicio = 2014;
    let filters = [
        {
            label: "Mês",
            name: "mes",
            type: "select",
            default: new Date().getMonth() + 1,
            options: [
                {value: null, label: "Todos"},
                ...new Array(12)
                    .fill(0)
                    .map((_, idx) => {
                        let month = moment().set("month", idx).format("MMMM")
                        return {value: idx + 1, label: month[0].toUpperCase() + month.slice(1)}
                    })
            ]
        },
        {
            label: "Ano",
            name: "ano",
            type: "select",
            default: ano_atual,
            options: [
                {value: null, label: "Todos"},
                ...new Array(ano_atual - ano_inicio + 1)
                    .fill(0)
                    .map((_, idx) => ({value: ano_atual - idx, label: ano_atual - idx}))
            ]
        }
    ]

    if (!produtos)
        return null;

    return <div className={"mt-3"}>
        <TabelaPaginada
            numero_resultados={paginacao ? -1 : 10}
            dataUrl={"/negocio/dashboard/ranking"}
            columns={columns}
            filters={filters}
            title={<>Ranking de Colaboradores
                <div className="form-check"><label className="form-check-label label-checkbox">
                    <input name="ativo" type="checkbox" className="form-check-input" value={paginacao} onChange={() => setPaginacao(!paginacao)}/>
                    <i className="input-helper"></i>
                    Mostrar todos
                </label></div>
            </>}
            showSearch={false}
        />
    </div>;
}
const DashboardAdmin = () => {
    const formulario = useFormulario();

    const [dashboard, setDashboard] = useState(null);

    function obterDashboard() {
        get(`/negocio/dashboard/geral`).then(setDashboard);
    }

    useEffect(() => {
        obterDashboard();
    }, [])

    if (!dashboard)
        return null;

    let diferenca_mes = dashboard.estatisticas_mes_aberto.a_pagar - dashboard.estatisticas_mes_aberto.a_pagar_anterior;
    let diferenca_ano = dashboard.estatisticas_ano.a_pagar - dashboard.estatisticas_ano.a_pagar_anterior;

    return <div className={"DashboardAdmin"}>
        <div className="row">
            <div className="col-md-4">
                <div className={"card mb-3"}>
                    <div className="card-padding">
                        <h4 className={"font-weight-bold massive-title primary-color mr-3 flex-grow-1"} style={{display: "inline"}}>Estatísticas Mês</h4>
                        <p className={"mt-3"}>
                            <span className={"destaque"}>{dashboard.estatisticas_mes_aberto.novos_colaboradores}</span> novos colaboradores este mês<br/>
                            <span className={"destaque"}>{formatarDinheiro(dashboard.estatisticas_mes_aberto.valor_carregamento, true)}</span> carregados este mês
                        </p>
                        <h4 className={"massive-title primary-color mr-3 flex-grow-1"} style={{display: "inline"}}>
                            Valor a pagar <strong className={"text-capitalize"}>{moment(dashboard.mes_aberto.data_inicio).format("MMMM")}</strong>:<br/>
                            {formatarDinheiro(dashboard.estatisticas_mes_aberto.a_pagar, true)} <span className={"destaque" + (diferenca_mes > 0 ? "" : " negativo")}>{diferenca_mes > 0 ? "+" : ""}{formatarDinheiro(diferenca_mes, true)}</span>
                        </h4>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className={"card mb-3"}>
                    <div className="card-padding">
                        <h4 className={"font-weight-bold massive-title primary-color mr-3 flex-grow-1"} style={{display: "inline"}}>Estatísticas Ano</h4>
                        <p className={"mt-3"}>
                            <span className={"destaque"}>{dashboard.estatisticas_ano.novos_colaboradores}</span> novos colaboradores este ano<br/>
                            <span className={"destaque"}>{formatarDinheiro(dashboard.estatisticas_ano.valor_carregamento, true)}</span> carregados este ano
                        </p>
                        <h4 className={"massive-title primary-color mr-3 flex-grow-1"} style={{display: "inline"}}>
                            Valor a pagar <strong>{moment().format("YYYY")}</strong>:<br/>
                            {formatarDinheiro(dashboard.estatisticas_ano.a_pagar, true)} <span className={"destaque" + (diferenca_ano > 0 ? "" : " negativo")}>{diferenca_ano > 0 ? "+" : ""}{formatarDinheiro(diferenca_ano, true)}</span>
                        </h4>
                    </div>
                </div>
            </div>
            <div className="col-md-4 grid-margin rounded">
                <PesquisarColaborador/>
                <div className={"p-3 card operation-card rounded flex-row"} onClick={() => {
                    formulario.abrirFormulario("importar_report", {
                        valoresIniciais: {
                            mes: dashboard.mes_aberto.id,
                        },
                        formularioSubmetido: async () => {
                            obterDashboard();
                        }
                    })
                }}>
                    <img width={40} src={AdicionarReport} className={"mr-2"} alt={"botão adicionar cliente"}/>
                    <p className={"flex-fill"}>Importar Report</p>
                </div>
                {dashboard.ultima_atualizacao && <p className={"mt-2"}>Negócio atualizado até: {moment(dashboard.ultima_atualizacao).format("LL")}</p>}
            </div>
        </div>
        <div className="row">
            <div className="col-md-6 stretch-card">
                <Colaboradores/>
            </div>
            <div className="col-md-6 stretch-card">
                <Clientes/>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6 stretch-card">
                <Cartoes/>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <Ranking/>
            </div>
        </div>
    </div>;
}

export default DashboardAdmin;