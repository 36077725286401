import {post} from "../../../../YoubizProvider";
import cogoToast from "cogo-toast";

const etapas = [
    {
        titulo: "Detalhes",
        formulario: require("../editar_colaborador/0").default
    },
    {
        titulo: "Pedido de cartões",
        formulario: require("../pedido_cartoes/0").default
    }
];

const toastoptions = {
    hideAfter: 5,
    position: 'top-right'
}

const submeter = async (valores, opcoes) => {
    valores.comissoes_personalizadas = valores.comissoes_personalizadas === "true";
    if (!valores.comissoes_personalizadas)
        delete valores.comissoes;

    valores.doc_identificacao_tipo = valores.doc_identificacao_tipo || null;

    let produtos = valores.produtos ? valores.produtos.map(({quantidade, id}, idx) => {
        if (!id || parseInt(quantidade || '0') === 0 || valores.produtos.findIndex(p => p.id === id) !== idx)
            return null;
        let produto = JSON.parse(id);
        return {
            quantidade,
            produto
        }
    }).filter(p => p) : [];

    delete valores.produtos;
    valores.pedido_cartoes = produtos;

    await post("/utilizador/" + opcoes.extra.id_utilizador + "/aceitar_colaborador", valores);
    cogoToast.success('Colaborador adicionado com sucesso', toastoptions);
};

const NovoColaborador = {
    titulo: "Novo Colaborador",
    submeter,
    etapas
};

export default NovoColaborador;