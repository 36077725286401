import React from 'react';

import InfoComprovativoIcon from '../../../../assets/images/comprovativo_info_icon.svg';
import CarregarComprovativo from '../../../../assets/images/carregar_comprovativo.svg';

import './styles.scss';

export default ({values, register, errors}) => {

    return <div className={"ImportarReportPopup"}>
        <div>
            <div>
                <div className="row m-0 py-5">
                    <div className="col-7 p-0 d-flex align-items-center">
                        <img src={InfoComprovativoIcon} alt={""} className={"mr-3"}/>
                        <p className={"m-0 text-comprovativo"}>Para importar o report de comissões por favor carregue o ficheiro .xls</p>
                    </div>
                    <div className="col-5 p-0 form-group m-0 d-flex flex-column justify-content-center">
                        <input type={"hidden"} name={"mes"} ref={register}/>
                        <label data-error={errors.relatorio?.message}>
                            <div className={"d-flex align-items-center"}>
                                <img src={CarregarComprovativo} alt={"botao carregar comprovativo"} className={"mr-2"}/>
                                <p className="m-0 text-comprovativo text-uppercase underline">
                                    Carregar report (.xlsx)
                                    {values.relatorio?.length > 0 ? <small>{values.relatorio[0].name}</small> : ""}
                                </p>
                            </div>
                            <input
                                className={"d-none"}
                                type={"file"}
                                name={"relatorio"}
                                ref={register({
                                    required: "Escolha o ficheiro para avançar"
                                })}
                                accept={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}/>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
}