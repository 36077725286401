import {useFormContext} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {get} from "../../YoubizProvider";

import './styles.scss';

const ProcurarProduto = ({errors, register, values, idxProduto, required, params = {}}) => {
    const {setValue} = useFormContext();

    const [resultados, setResultados] = useState(null);
    const [valorPesquisa, setValorPesquisa] = useState("");

    useEffect(() => {
        if (!values.produtos || !values.produtos[idxProduto] || !values.produtos[idxProduto].id)
            return;
        let produto = JSON.parse(values.produtos[idxProduto].id);
        setValorPesquisa(`${produto.nome}`);
    }, [values.produtos && values.produtos[idxProduto]]);

    return <div className={"ProcurarProduto"}>
        <div className="form-group mb-0">
            <label data-error={errors.produtos && errors.produtos[idxProduto]?.id?.message}>
                <input placeholder="Produto" type="text" className="form-control form-control-sm"
                       value={valorPesquisa}
                       onChange={async e => {
                           let valorPesquisa = e.target.value;
                           setValorPesquisa(valorPesquisa);

                           if (valorPesquisa === '') {
                               setResultados(null);
                               return;
                           }

                           let produtos = await get("/cartoes/produtos/listar", {
                               pesquisa: valorPesquisa,
                               ...params
                           });
                           setResultados(produtos.resultados)
                       }}
                />
            </label>
            <input
                name={"produtos[" + idxProduto + "].id"}
                placeholder="ESCONDIDO"
                type="text"
                className="d-none"
                ref={register({
                    required: (required || idxProduto === 0) ? "Por favor indique um produto" : null
                })}/>
            <input
                name={"produtos[" + idxProduto + "].quantidades"}
                placeholder="ESCONDIDO"
                type="text"
                className="d-none"
                ref={register({
                    required: false
                })}/>
            <input
                name={"produtos[" + idxProduto + "].nome"}
                placeholder="ESCONDIDO"
                type="text"
                className="d-none"
                ref={register({
                    required: false
                })}/>
        </div>
        {resultados && <div className={"resultados-produto"}>
            {resultados.map(produto => {
                return <div className={"resultado-produto"}>
                    <p onClick={() => {
                        setValue("produtos[" + idxProduto + "].id", JSON.stringify(produto));
                        setValue("produtos[" + idxProduto + "].quantidades", produto.quantidades);
                        setValue("produtos[" + idxProduto + "].nome", produto.nome);
                        setValorPesquisa(`${produto.nome}`);
                        setResultados(null);
                    }}>
                        {produto.nome}<br/>
                        <small>
                            código: {produto.codigo}
                        </small>
                    </p>
                </div>
            })}

            {resultados.length === 0 && <p
                style={{lineHeight: "30px", margin: 0, padding: 7}}>
                Não foram encontrados resultados
            </p>}
        </div>}
    </div>;
}

export default ProcurarProduto;