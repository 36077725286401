import {post} from "../../../../YoubizProvider";
import {serialize} from "object-to-formdata";
import cogoToast from "cogo-toast";

const etapas = [
    {
        titulo: "1. Dados do Pedido",
        formulario: require('./0').default
    },
    {
        titulo: "2. Resumo",
        formulario: require('./1').default
    }
];

const toastoptions = {
    hideAfter: 5,
    position: 'top-right'
}

const submeter = async (valores, opcoes) => {
    let id_colaborador = valores.colaborador ? JSON.parse(valores.colaborador).id : opcoes.extra?.colaborador;

    let produtos = valores.produtos ? valores.produtos.map(({quantidade, id}, idx) => {
        if (!id || quantidade === '' || parseInt(quantidade) === 0 || valores.produtos.findIndex(p => p.id === id) !== idx)
            return null;
        let produto = JSON.parse(id);
        return {
            quantidade,
            produto
        }
    }).filter(p => p) : [];

    let formData = serialize({utilizador: id_colaborador, pedido_cartoes: produtos}, {indices: true});

    let movimento = await post("/cartoes/pedidos/criar", formData);
    cogoToast.success('Pedido registado com sucesso', toastoptions);

    return movimento;
};

const PedidoCartoes = {
    titulo: "Novo pedido de cartões",
    submeter,
    etapas
};

export default PedidoCartoes;
