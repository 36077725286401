import React, {useEffect, useRef, useState} from 'react';
import TabelaPaginada from "../../components/TabelaPaginada";
import moment from "moment";
import "moment/locale/pt";
import {get, post, useYoubiz} from "../../YoubizProvider";
import formatarDinheiro from "../../functions/formatar_dinheiro";
import {acoesPagamento} from "../GestaoFinanceira";
import {Link, useHistory, useParams} from "react-router-dom";
import {useFormulario} from "../../components/Formularios/Provider";

import "./styles.scss";
import BadgeConsultor from "../../assets/images/badge_consultor.svg";
import BadgeCoordenador from "../../assets/images/badge_coordenador.svg";
import BadgeExecutivo from "../../assets/images/badge_executivo.svg";
import Editar from "../../assets/images/editar.svg";
import PedirCartoes from "../../assets/images/pedir_cartoes.svg";
import cogoToast from "cogo-toast";
import IconLink from "../../assets/images/link.svg";
import estados_pagamento from "../../misc/estados_pagamento";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

moment.locale("pt");

const Pagamentos = ({id_utilizador, data_registo, mes_fechado, valor_pagamento_pendente}) => {
    let columns = [
        {
            dataField: 'mes_inicio',
            text: 'Mês',
            formatter: (data, pagamento) => <><span className={"text-capitalize"}>{moment(data).format("MMMM YYYY")}</span>{pagamento.acerto ? " (acerto)" : ""}</>
        }, {
            dataField: 'patamar_designacao',
            text: 'Patamar',
            formatter: patamar => patamar || "-"
        }, {
            dataField: 'valor_comissao',
            text: 'Comissões',
            formatter: d => <span className={"badge_valor" + (d > 0 ? " green" : "")}>{formatarDinheiro(d, true)}</span>
        }, {
            dataField: 'valor_premio',
            text: 'Prémios',
            formatter: d => <span className={"badge_valor" + (d > 0 ? " green" : "")}>{formatarDinheiro(d, true)}</span>
        }, {
            text: 'Despesas',
            formatter: (_, pagamento) => {
                return <>
                    <small>C.O: </small>{formatarDinheiro(pagamento.valor_custos_operacionais, true)} <br/>
                    <small>D.A: </small>{formatarDinheiro(pagamento.valor_despesas_administrativas, true)}
                </>
            }
        }, {
            dataField: 'valor_a_pagar',
            text: 'A receber',
            formatter: d => <span className={"badge_valor primary"}>{formatarDinheiro(d, true)}</span>
        }, {
            dataField: 'estado',
            text: 'Estado',
            formatter(estado) {
                return <span style={{textTransform: "uppercase", whiteSpace: "nowrap", backgroundColor: estados_pagamento[estado].cor, color: "white", borderRadius: "7px", padding: "8px"}}>{estados_pagamento[estado].designacao}</span>
            }
        }, {
            dataField: 'action',
            text: 'Ações',
            formatter: acoesPagamento
        }
    ];

    let ano_atual = new Date().getFullYear();
    let ano_inicio = new Date(data_registo || null).getFullYear();
    let filters = [{
        label: "Ano",
        name: "ano",
        type: "select",
        default: ano_atual,
        options: new Array(ano_atual - ano_inicio + 1).fill(0)
            .map((_, idx) => ({value: ano_atual - idx, label: ano_atual - idx}))
    }]

    return <div className={"mt-3"}>
        <TabelaPaginada
            numero_resultados={50}
            dataUrl={"/financas/pagamentos/listar"}
            fetchData={async (filtros) => {
                let dados = await get("/financas/pagamentos/listar", filtros);

                let data_inicio = moment(filtros.ano + "-01-01");
                if (data_inicio.isBefore(moment(data_registo)))
                    data_inicio = moment(data_registo).startOf("month");

                let data_fim = moment(mes_fechado.data).endOf("month");
                if (data_fim.isAfter(data_inicio, "year"))
                    data_fim = data_inicio.clone().endOf("year");

                let patamar = {id: 1, designacao: "Colaborador"};

                // Preencher meses não existentes até ao atual
                while (!data_inicio.isAfter(data_fim, "month")) {
                    let pagamento = dados.resultados.find(p => moment(p.mes_inicio).isSame(data_inicio, "month"));
                    if (pagamento) {
                        patamar = {
                            id: pagamento.patamar,
                            designacao: pagamento.patamar_designacao,
                        };
                    } else {
                        dados.resultados.push({
                            mes_inicio: data_inicio.format("YYYY-MM-DD"),
                            valor_comissao: 0,
                            valor_premio: 0,
                            valor_a_pagar: 0,
                            valor_custos_operacionais: 0,
                            valor_despesas_administrativas: 0,
                            estado: "vazio"
                        })
                    }
                    data_inicio.add(1, "month");
                }

                dados.resultados.sort((a, b) => moment(b.mes_inicio).diff(moment(a.mes_inicio)));

                return dados;
            }}
            columns={columns}
            title={<>
                Conta corrente<br/>
                <small className={"d-block"}><strong>{formatarDinheiro(valor_pagamento_pendente, true)}</strong> pendentes de pagamento</small>
            </>}
            mostrarExportacao
            filters={filters}
            filtro={{utilizador: id_utilizador}}
        />
    </div>
}

const Notas = ({perfil}) => {
    const timeout = useRef(0);
    const [sucesso, setSucesso] = useState(false);

    const onChange = (e) => {
        setSucesso(false);
        clearTimeout(timeout.current);
        let valor = e.target.value;
        timeout.current = setTimeout(async () => {
            await post(`/utilizador/${perfil.id}/editar_notas`, {notas: valor});
            cogoToast.success('Notas guardadas', {hideAfter: 5, position: 'top-right'});
            setSucesso(true);
        }, 1000);
    }

    return <div className={"card mb-3"}>
        <div className="card-padding">
            <p className={"font-weight-bold table-title primary-color mr-3 flex-grow-1"}>Notas{sucesso ? <> <i className={"fa fa-check-circle"} style={{color: "#3ac73a"}}/></> : null}</p>
            <div>
                <textarea className="form-control form-control-sm" rows="4" onChange={onChange} style={{borderColor: sucesso ? "#3ac73a" : undefined}}>
                    {perfil.notas}
                </textarea>
            </div>
        </div>
    </div>
}

const LinksUteis = () => {
    const [linksUteis, setLinksUteis] = React.useState([]);
    useEffect(() => {
        get("/negocio/links_uteis/listar").then(setLinksUteis);
    }, [])

    return <div className="row">
        <div className="col-md-6">
            <div className={"card mb-3"}>
                <div className="card-padding">
                    <p className={"font-weight-bold table-title primary-color mr-3 flex-grow-1 mb-0"}>Links úteis</p>
                    <p className={"table-sub-title primary-color mr-3 flex-grow-1"}>Mais informações sobre a Youbiz</p>

                    {linksUteis.map(link => <a style={{display: "flex", opacity: link.visivel ? 1 : 0.5}} href={link.url} target={"_blank"}>
                        <img src={IconLink} alt={""} className={"mt-n2 mr-2"}/>
                        <p className={"table-sub-title primary-color flex-grow-1 mt-1 underline"}>{link.titulo}{link.visivel ? "" : " (escondido)"}</p>
                    </a>)}
                </div>
            </div>
        </div>
    </div>
}

const PerfilColaborador = () => {
    const params = useParams();
    const youbiz = useYoubiz();
    const formulario = useFormulario();
    const history = useHistory();

    const [perfil, setPerfil] = useState(null);
    const [equipa, setEquipa] = useState(null);
    const [estatisticas, setEstatisticas] = useState(null);
    const [estatisticas_pendentes, setEstatisticasPendentes] = useState(true);
    const [mesEstatisticas, setMesEstatisticas] = useState(new Date().getMonth() + 1);
    const [anoEstatisticas, setAnoEstatisticas] = useState(new Date().getFullYear());

    const id_utilizador = params.id || youbiz.utilizador.id;

    let obterDetalhes = async () => {
        get(`/utilizador/${id_utilizador}/perfil/base`).then(setPerfil)
        get(`/utilizador/${id_utilizador}/perfil/equipa`).then(setEquipa)
    }

    useEffect(() => {
        obterDetalhes();
    }, [])

    useEffect(() => {
        let ano = parseInt(anoEstatisticas) || null;
        let mes = parseInt(mesEstatisticas) || null;
        if (!ano && mes) {
            setMesEstatisticas("0");
            return;
        }

        setEstatisticasPendentes(true);
        get(`/utilizador/${id_utilizador}/perfil/estatisticas`, {mes, ano})
            .then(setEstatisticas)
            .then(() => setEstatisticasPendentes(false));
    }, [mesEstatisticas, anoEstatisticas]);

    if (!perfil)
        return null;

    let dias_passados_mes = Math.max(1, moment(perfil.ultima_atualizacao).diff(perfil.mes_aberto.mes.data, "days"));
    let dias_mes = moment(perfil.mes_aberto.mes.data).daysInMonth();

    return <div className={"PerfilColaborador"}>
        <div className="row">
            <div className={"col-md-12"}>
                <h5 className={"text-muted underline mt-n2 mb-4"}>Negócio atualizado até: <strong>{moment(perfil.ultima_atualizacao).format("LL")}</strong></h5>
            </div>
            <div className={"col-md-9"}>
                <div className={"card mb-3"}>
                    <div className="card-padding">
                        <p className="font-weight-bold massive-title primary-color mr-3 flex-grow-1 d-flex justify-content-between">
                            <span style={perfil.data_desativado ? {textDecoration: "line-through"} : null}>{perfil.nome}</span>
                            {youbiz.utilizador.tipo === "admin" && <img
                                src={Editar}
                                className={"edit-icon"}
                                onClick={() => {
                                    let valores_iniciais = {
                                        ...perfil,
                                        comissoes_personalizadas: perfil.comissoes_personalizadas ? "true" : "false",
                                        ativo: !perfil.data_desativado,
                                        numero_ascendente: perfil.cartao_associado?.vendedor_telemovel,
                                        numero_telemovel_youbiz: perfil.cartao_associado?.num_telemovel,
                                        patamar: perfil.patamar.id
                                    };

                                    ['doc_identificacao_validade', 'doc_identificacao_emissao', 'data_nascimento'].forEach(campo => {
                                        valores_iniciais[campo] = valores_iniciais[campo] ? new Date(valores_iniciais[campo]) : null;
                                    });

                                    formulario.abrirFormulario("editar_colaborador", {
                                        extra: {
                                            id_utilizador: perfil.id
                                        },
                                        valoresIniciais: valores_iniciais,
                                        formularioSubmetido: obterDetalhes
                                    })
                                }
                                }
                            />}
                            {youbiz.utilizador.tipo === "colaborador" && <img
                                src={Editar}
                                className={"edit-icon"}
                                onClick={() => {
                                    let valores_iniciais = {
                                        ...perfil
                                    };

                                    ['doc_identificacao_validade', 'doc_identificacao_emissao', 'data_nascimento'].forEach(campo => {
                                        valores_iniciais[campo] = valores_iniciais[campo] ? new Date(valores_iniciais[campo]) : null;
                                    });

                                    formulario.abrirFormulario("editar_dados_pessoais", {
                                        valoresIniciais: valores_iniciais,
                                        formularioSubmetido: obterDetalhes
                                    })
                                }
                                }
                            />}
                        </p>
                        <div className="row " style={{marginBottom: "25px", marginTop: "15px"}}>
                            <div className={"col-lg-8"}>
                                <div className={"d-flex justify-content-between flex-column flex-xl-row "}>
                                    <div style={{marginRight: 25}}>
                                        <p>
                                            <b>ID:</b> {perfil.numero_colaborador}<br/>
                                            <b>Morada:</b> {perfil.morada}<br/>
                                            <b>Cod. Postal:</b> {perfil.codigo_postal} {perfil.localidade}<br/>
                                            <b>Nº Contacto: </b> {perfil.telemovel}<br/>
                                        </p>
                                        {/*<a className={"button_text mt-3"}>Ver mais</a>*/}
                                    </div>
                                    <div>
                                        <p>
                                            {perfil.cartao_associado && <><b>Nº NOS:</b> {perfil.cartao_associado.num_telemovel}<br/></>}
                                            {perfil.cartao_associado?.vendedor_telemovel && <><b>Nº NOS Ascendente:</b> <Link className={"link"} target={"_blank"} to={"/colaborador/" + perfil.cartao_associado.vendedor_id}>{perfil.cartao_associado.vendedor_telemovel}</Link><br/></>}
                                            <b>Email:</b> {perfil.email}<br/>
                                        </p>
                                    </div>
                                </div>
                                <div className={"timeline_meses"}>
                                    <div>
                                        <p style={{textTransform: "capitalize"}}>{moment(perfil.mes_fechado.mes.data).format("MMMM YYYY")} <span> <i className={"fa fa-lock"}/> FECHADO</span></p>
                                        <h5>Total a receber: {formatarDinheiro(perfil.mes_fechado.pagamento?.valor_a_pagar || 0, true)}</h5>
                                    </div>
                                    <div>
                                        <p style={{textTransform: "capitalize"}}>{moment(perfil.mes_aberto.mes.data).format("MMMM YYYY")}<span className={"light"}>ABERTO</span></p>
                                        <h5>Total a receber: {formatarDinheiro(perfil.mes_aberto.pagamento, true)}</h5>
                                        <p className={"previsao"}>Previsão para o mês: <strong>{formatarDinheiro((perfil.mes_aberto.pagamento / dias_passados_mes) * dias_mes, true)}</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-lg-4 pt-5 pt-lg-0"} style={{textAlign: "center"}}>
                                <img src={{CONSULTOR: BadgeConsultor, EXECUTIVO: BadgeExecutivo, COORDENADOR: BadgeCoordenador}[perfil.patamar.codigo]} className={"badge-user"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-3 grid-margin rounded">
                <div className={"p-3 card operation-card rounded flex-row"} onClick={() => {
                    formulario.abrirFormulario("pedido_cartoes", {
                        extra: {
                            preencher_quantidades: youbiz.utilizador.tipo === "admin",
                            colaborador: perfil
                        },
                        formularioSubmetido: youbiz.utilizador.tipo === "admin" ? () => history.push(`/gestao/pedidos_cartoes`) : null
                    })
                }}>
                    <img width={40} src={PedirCartoes} className={"mr-2"} alt={"botão adicionar cliente"}/>
                    <p className={"flex-fill"}>Pedir Cartões</p>
                </div>
            </div>
        </div>
        <div className={"row"}>
            <div className={"col-md-9 stretch-card flex-column"}>
                <div className="d-flex justify-content-between mt-3">
                    <div className="card-aba">Estatísticas</div>
                    <div className={"mt-2"}>
                        <select
                            onChange={e => setMesEstatisticas(e.target.value)}
                            value={mesEstatisticas}
                            className={"select-estatisticas text-muted underline"}>
                            <option value={0}>Todos os meses</option>
                            {new Array(12).fill(0).map((_, idx) => {
                                let month = moment().set("month", idx).format("MMMM")
                                return <option value={idx + 1}>{month[0].toUpperCase() + month.slice(1)}</option>
                            })}
                        </select>
                        <select
                            onChange={e => setAnoEstatisticas(e.target.value)}
                            value={anoEstatisticas}
                            className={"select-estatisticas text-muted underline ml-3"}>
                            <option value={0}>Todos os anos</option>
                            {new Array(new Date().getFullYear() - new Date(perfil.data_criacao || null).getFullYear() + 1).fill(0).map((_, idx) => {
                                let year = new Date().getFullYear() - idx;
                                return <option value={year}>{year}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div className={"card mb-3 flex-fill estatisticas_perfil"}>
                    {estatisticas_pendentes && <div className={"loading_overlay"}>
                        <i className={"fa fa-spinner rotate"}/>
                    </div>}
                    <div className="card-padding">
                        {estatisticas && <div className="d-flex justify-content-between flex-column flex-md-row">
                            <div>
                                <p className={"font-weight-bold table-title primary-color mr-3 flex-grow-1"}>Comissões
                                    {estatisticas.comissoes.estimado && <OverlayTrigger overlay={<Tooltip>O valor apresentado é estimado a partir da sua equipa e patamar atual e poderá não corresponder à realidade. Verifique a conta corrente para a informação mensal.</Tooltip>}>
                                        <sup>(*)</sup>
                                    </OverlayTrigger>}</p>
                                <p>
                                    Total diretos: <b>{formatarDinheiro(estatisticas.comissoes.niveis.find(c => c.nivel === 1)?.comissao || 0, true)}</b><br/>
                                    Total 2ª linha: <b>{formatarDinheiro(estatisticas.comissoes.niveis.find(c => c.nivel === 2)?.comissao || 0, true)}</b><br/>
                                    Total 3ª linha: <b>{formatarDinheiro(estatisticas.comissoes.niveis.find(c => c.nivel === 3)?.comissao || 0, true)}</b><br/>
                                    Total do período: <b>{formatarDinheiro(estatisticas.comissoes.comissoes_totais || 0, true)}</b><br/>
                                    Prémios do período: <b>{formatarDinheiro(estatisticas.comissoes.premios_totais || 0, true)}</b><br/>
                                    Despesas administrativas: <b>{formatarDinheiro(estatisticas.comissoes.despesas_efetivas || 0, true)}</b><br/>
                                    <strong>Resultado do período: {formatarDinheiro(estatisticas.comissoes.valor_a_receber || 0, true)}</strong>
                                </p>
                            </div>
                            <div>
                                <p className={"font-weight-bold table-title primary-color mr-3 flex-grow-1"}>Carregamentos</p>
                                <p>
                                    Nº de carregamentos da equipa: <b>{estatisticas.comissoes.niveis.reduce((acc, i) => acc + i.contagem, 0) || 0}</b><br/>
                                    Nº de carregamentos diretos: <b>{estatisticas.comissoes.niveis.find(c => c.nivel === 1)?.contagem || 0}</b><br/>
                                    {youbiz.utilizador.tipo === 'admin' && <>Valor total carregado: <b>{formatarDinheiro(estatisticas.total_carregado, true)}</b></>}
                                    {/*Novos clientes: <b>3</b><img src={Visualizar} className={"ver_detalhes_clientes"}/>*/}
                                    {/*<div className="row my-3">
                                        <img src={Arvore} className={"arvore_button"}/>
                                        <a className={"button_text mx-2"}>Consultar àrvore de negócio</a>
                                    </div>*/}
                                </p>
                            </div>
                            <div>
                                <p className={"font-weight-bold table-title primary-color mr-3 flex-grow-1"}>Cartões</p>
                                {estatisticas ? <p>
                                    Cartões recebidos: <b>{estatisticas.cartoes.cartoes}</b><br/>
                                    Cartões ativados: <b>{estatisticas.cartoes.clientes.total} {estatisticas.cartoes.clientes.detalhe.length > 0 && <OverlayTrigger
                                    overlay={<Tooltip>{estatisticas.cartoes.clientes.detalhe.map(p => <div className={"text-left"}><strong>{p.count}x</strong> {p.nome}<br/></div>)}</Tooltip>}><i className={"fa fa-eye"}/></OverlayTrigger>}</b><br/>
                                    Taxa de ativação: <b>{estatisticas.cartoes.taxa_ativacao.toFixed(1)}%</b><br/>
                                </p> : "A obter informações"}
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            <div className={"col-md-3 stretch-card flex-column estatisticas_perfil"}>
                <div className="d-flex justify-content-between mt-3">
                    <div className="card-aba">Equipa</div>
                </div>
                <div className={"card mb-3 flex-fill"}>
                    <div className="card-padding">
                        <p className={"font-weight-bold table-title primary-color mr-3 flex-grow-1"}>Equipa</p>
                        {equipa ? <>
                            <p>
                                Descendentes diretos: <b>{equipa.find(e => e.nivel === 1)?.contagem || 0}</b><br/>
                                Descendentes 2ª linha: <b>{equipa.find(e => e.nivel === 2)?.contagem || 0}</b><br/>
                                Descendentes 3ª linha: <b>{equipa.find(e => e.nivel === 3)?.contagem || 0}</b><br/>
                                Total da Equipa: <b>{equipa.reduce((acc, i) => acc + i.contagem, 0) || 0}</b>
                            </p>
                        </> : "A obter informações"}
                    </div>
                </div>
            </div>
        </div>

        {youbiz.utilizador.tipo === 'admin' ? <>
            <div className="row">
                <div className="col-md-12">
                    <div className={"mb-4"}>
                        <Pagamentos id_utilizador={perfil.id}
                                    data_registo={perfil.data_criacao}
                                    mes_fechado={perfil.mes_fechado.mes}
                                    valor_pagamento_pendente={perfil.valor_pagamento_pendente}/>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <Notas perfil={perfil}/>
                </div>
            </div>
        </> : <>
            <LinksUteis/>
        </>}
    </div>;
}

export default PerfilColaborador;