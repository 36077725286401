import React, {useMemo} from 'react';

export default ({values, register, errors}) => {
    let colaborador = values.colaborador ? JSON.parse(values.colaborador) : null;

    let produtos = useMemo(() => {
        return values.produtos ? values.produtos.map(({quantidade, id}, idx) => {
            if (!id || quantidade === '' || parseInt(quantidade) === 0 || values.produtos.findIndex(p => p.id === id) !== idx)
                return null;
            let produto = JSON.parse(id);
            return {
                quantidade,
                produto
            }
        }).filter(p => p) : [];
    }, [values.produtos])

    return <div className={"NovaVenda"}>
        {colaborador && <>
            <p>Colaborador</p>
            <p>
                <strong>{colaborador.nome}</strong><br/>
                <strong className={"text-uppercase"}>NIF </strong>{colaborador.nif || colaborador.email}
            </p>
        </>}

        <p>Resumo do Pedido</p>

        {produtos.map(({quantidade, produto}) => {
            return <div className="mb-3">
                {quantidade} x <strong>{produto.nome}</strong>
            </div>;
        })}
    </div>
}