import React from 'react';
import InfoComprovativoIcon from "../../../../assets/images/comprovativo_info_icon.svg";

export default ({values}) => {
    return <div className={"ImportarReportPopup"}>
        <div>
            <div>
                <div className="row m-0 py-5">
                    <div className="col-12 p-0 d-flex align-items-center">
                        <img src={InfoComprovativoIcon} alt={""} className={"mr-3"}/>
                        <p className={"m-0 text-comprovativo"}>Será importado o report de comissões <b>{values.relatorio?.[0]?.name}</b> e serão atualizadas as comissões para o mês em aberto.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
}