import React, {useEffect, useState} from 'react';
import TabelaPaginada from "../../components/TabelaPaginada";
import formatarDinheiro from "../../functions/formatar_dinheiro";
import moment from "moment";
import "moment/locale/pt";
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Button, Modal} from "react-bootstrap";
import estados_pagamento from "../../misc/estados_pagamento";
import {get, useYoubiz} from "../../YoubizProvider";

import "./styles.scss";

moment.locale("pt");

const Pagamentos = () => {
    const youbiz = useYoubiz();

    const [perfil, setPerfil] = useState(null);
    useEffect(() => {
        get(`/utilizador/${youbiz.utilizador.id}/perfil/base`).then(setPerfil)
    }, []);

    if (!perfil) return null;

    let ano_atual = new Date().getFullYear();
    let ano_inicio = new Date(perfil.data_aprovacao || null).getFullYear();
    let filters = [{
        label: "Ano",
        name: "ano",
        type: "select",
        default: ano_atual,
        options: new Array(ano_atual - ano_inicio + 1).fill(0)
            .map((_, idx) => ({value: ano_atual - idx, label: ano_atual - idx}))
    }]

    let columns = [
        {
            dataField: 'mes_inicio',
            text: 'Mês',
            formatter: (data) => <span className={"text-capitalize"}>{moment(data).format("MMMM YYYY")}</span>
        }, {
            dataField: 'patamar_designacao',
            text: 'Patamar',
            formatter: patamar => patamar || "-"
        }, {
            dataField: 'valor_comissao',
            text: 'Comissões',
            formatter: d => <span className={"badge_valor" + (d > 0 ? " green" : "")}>{formatarDinheiro(d, true)}</span>
        }, {
            dataField: 'valor_premio',
            text: 'Prémios',
            formatter: d => <span className={"badge_valor" + (d > 0 ? " green" : "")}>{formatarDinheiro(d, true)}</span>
        }, {
            text: 'Despesas',
            formatter: (_, pagamento) => {
                return <>
                    <small>C.O: </small>{formatarDinheiro(pagamento.valor_custos_operacionais, true)} <br/>
                    <small>D.A: </small>{formatarDinheiro(pagamento.valor_despesas_administrativas, true)}
                </>
            }
        }, {
            dataField: 'valor_a_pagar',
            text: 'A receber',
            formatter: d => <span className={"badge_valor primary"}>{formatarDinheiro(d, true)}</span>
        }, {
            dataField: 'estado',
            text: 'Estado',
            formatter(estado) {
                return <span style={{textTransform: "uppercase", whiteSpace: "nowrap", backgroundColor: estados_pagamento[estado].cor, color: "white", borderRadius: "7px", padding: "8px"}}>{estados_pagamento[estado].designacao}</span>
            }
        }
    ];

    return <div className={"mt-3"}>
        <TabelaPaginada
            numero_resultados={50}
            filters={filters}
            fetchData={async (filtros) => {
                let dados = await get("/financas/pagamentos/listar", filtros);

                let data_inicio = moment(filtros.ano + "-01-01");
                if (data_inicio.isBefore(moment(youbiz.utilizador.data_aprovacao)))
                    data_inicio = moment(youbiz.utilizador.data_aprovacao).startOf("month");

                let data_fim = moment(perfil.mes_fechado.mes.data).endOf("month");
                if (data_fim.isAfter(data_inicio, "year"))
                    data_fim = data_inicio.clone().endOf("year");

                let patamar = {id: 1, designacao: "Colaborador"};

                // Preencher meses não existentes até ao atual
                while (!data_inicio.isAfter(data_fim, "month")) {
                    let pagamento = dados.resultados.find(p => moment(p.mes_inicio).isSame(data_inicio, "month"));
                    if (pagamento) {
                        patamar = {
                            id: pagamento.patamar,
                            designacao: pagamento.patamar_designacao,
                        };
                    } else {
                        dados.resultados.push({
                            mes_inicio: data_inicio.format("YYYY-MM-DD"),
                            valor_comissao: 0,
                            valor_premio: 0,
                            valor_a_pagar: 0,
                            valor_custos_operacionais: 0,
                            valor_despesas_administrativas: 0,
                            estado: "vazio"
                        })
                    }
                    data_inicio.add(1, "month");
                }

                dados.resultados.sort((a, b) => moment(b.mes_inicio).diff(moment(a.mes_inicio)));

                return dados;
            }}
            columns={columns}
            title={<>
                Conta corrente<br/>
                <small className={"d-block"}><strong>{formatarDinheiro(perfil.valor_pagamento_pendente, true)}</strong> pendentes de pagamento</small>
            </>}
        />
    </div>
}

const ModalInfo = () => {
    return <div className={"modal_procedimentos_pagamento"}>
        <Modal.Header closeButton>
            <Modal.Title>Procedimentos de Pagamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>Para receber as suas comissões no negócio YB Telecom pode optar por várias alternativas. Escolha a opção que lhe for mais conveniente e verifique o respetivo procedimento de pagamento:</p>
            <h4><strong>Recibo verde</strong></h4>
            <p>
                Pode optar por receber as suas comissões através de recibo verde. Para mais informações aceder ao Portal da Finanças (www.portaldasfinancas.gov.pt) em Questões frequentes / Guia fiscal / Sistema de emissão de Actos Recibos Verdes.<br/>
                <br/>
                Para preencher o Recibo Verde:<br/>
                1. Entrar no Portal das Finanças<br/>
                2. Obter<br/>
                3. Emitir Recibo Verde<br/>
                <br/>
                <strong>Importância:</strong> Valor das suas Comissões<br/>
                <strong>Regime de Iva:</strong> Taxa de 23 % , caso não esteja ISENTO. Em caso de isenção colocar o Artigo correto.<br/>
                <strong>Base de Incidência em IRS:</strong> Taxa 25%. (depende da forma como tem atividade aberta nas Finanças, em caso de isenção colocar o artigo correto.<br/>
                <strong>Imposto Selo:</strong> Zero<br/>
                <strong>A título de:</strong> Honorários<br/>
                <br/>
                Verifique o valor que tem a receber na sua conta corrente e envie-nos o recibo devidamente preenchido para: financeiro@youbiz.pt ou para Doca de Alcântara, Armazém 3, 1300-299 Lisboa.<br/>
                <br/>
                <small>As datas de pagamento são o dia 5 e 20 de cada mês, através de transferência bancária.</small>
            </p>
            <h4><strong>Fatura</strong></h4>
            <p>
                Uma das formas de receber as suas comissões é através de uma fatura. Para o efeito apenas tem de nos enviar a fatura devidamente preenchida para:<br/>
                <br/>
                <strong>YouBIZ, LDA.</strong><br/>
                Doca de Alcântara<br/>
                Armazém 3<br/>
                1300-299 Lisboa<br/>
                Portugal<br/>
                NIF: 509000495<br/>
                <br/>
                <strong>Importância:</strong> Valor das suas Comissões<br/>
                <strong>Regime de Iva:</strong> Taxa de 23 % , caso não esteja ISENTO. Em caso de isenção colocar o Artigo correto.<br/>
                <strong>Base de Incidência em IRS:</strong> Taxa 25%. (depende da forma como tem atividade aberta nas Finanças, em caso de isenção colocar o artigo correto.<br/>
                <br/>
                Verifique o valor que tem a receber na sua conta corrente e envie-nos o recibo devidamente preenchido para: financeiro@youbiz.pt ou para Doca de Alcântara, Armazém 3, 1300-299 Lisboa.<br/>
                <br/>
                <small>As datas de pagamento são o dia 5 e 20 de cada mês, através de transferência bancária.</small>
            </p>
            <h4><strong>Ato Único Isolado</strong></h4>
            <p>
                Pode optar por receber as suas comissões através de ato único isolado.<br/>
                <br/>
                Para mais informações aceder ao Portal da Finanças (www.portaldasfinancas.gov.pt) em Questões frequentes / Guia fiscal / Sistema de emissão de Actos Único Isolado.<br/>
                <br/>
                <strong>Este documento só é válido para quem não tem qualquer actividade aberta nas FINANÇAS e, só pode ser passado UMA VEZ por ANO.</strong><br/>
                <br/>
                Para preencher o Ato Isolado:<br/>
                1. Entrar no Portal das Finanças<br/>
                2. Obter<br/>
                3. Emitir Recibo Ato Isolado<br/>
                <br/>
                <strong>Importância:</strong> Valor das suas Comissões<br/>
                <strong>Regime de Iva:</strong> Taxa de 23 % (obrigatório)<br/>
                <strong>Base de Incidência em IRS:</strong> Isento<br/>
                <strong>Imposto Selo:</strong> Zero<br/>
                <strong>A título de:</strong> Honorários<br/>
                <br/>
                Verifique o valor que tem a receber na sua conta corrente e envie-nos o recibo devidamente preenchido para: financeiro@youbiz.pt ou para Doca de Alcântara, Armazém 3, 1300-299 Lisboa.<br/>
                <br/>
                <small>As datas de pagamento são o dia 5 e 20 de cada mês, através de transferência bancária.</small>
            </p>
        </Modal.Body>
    </div>;
}

const GestaoFinanceiraColaborador = () => {
    const [modalInfo, setModalInfo] = useState(false);

    return <div className={"GestaoFinanceiraColaborador"}>
        <Modal
            size={"lg"}
            style={{}}
            show={modalInfo}
            onHide={() => setModalInfo(false)}>
            <ModalInfo/>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setModalInfo(false)}>Fechar</Button>
            </Modal.Footer>
        </Modal>
        <div className={"card mb-3"}>
            <div className="card-padding">
                <p className={"font-weight-bold table-title primary-color mr-3 flex-grow-1"}>Pagamentos</p>
                <p className={"mb-0"}>Para receber as suas comissões no negócio YB Telecom pode optar por várias alternativas. <a className="link" href={"#"} onClick={() => setModalInfo(true)}>Ver instruções</a>.</p>
            </div>
        </div>
        <Pagamentos/>
    </div>
};

export default GestaoFinanceiraColaborador;