import React, {useMemo, useState} from 'react';

import IconLoading from "../../../../assets/images/a_calcular.svg";
import IconDone from "../../../../assets/images/done_icon.svg";
import {Modal} from "react-bootstrap";
import parse from "html-react-parser";

export default ({values, register, errors}) => {
    let resultado = useMemo(() => {
        return values.resultado ? JSON.parse(values.resultado) : null;
    }, [values.resultado]);
    const [mostrarRelatorio, setMostrarRelatorio] = useState(false);

    return <div className={"ImportarReportPopup"}>
        {resultado && <Modal
            size={"lg"}
            backdrop={'static'}
            show={mostrarRelatorio}
            onHide={() => setMostrarRelatorio(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Relatório</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {parse(resultado.report.replace(/\n/g, "<br>"))}
            </Modal.Body>
        </Modal>}
        <input type={"hidden"} name={"resultado"} ref={register}/>
        <div>
            <div>
                {!resultado && <div className="row m-0 py-5">
                    <div className="col-12 p-0 d-flex align-items-center">
                        <img src={IconLoading} alt={""} className={"mr-3 rotate"}/>
                        <p className={"m-0 text-comprovativo"}>A importar o relatório e a calcular as comissões…</p>
                    </div>
                </div>}
                {resultado && <div className="row m-0 py-5">
                    <div className="col-12 p-0 d-flex align-items-center">
                        <img src={IconDone} alt={""} className={"mr-3"}/>
                        <p className={"m-0 text-comprovativo"}>A importação do relatório foi feita com sucesso, foram <strong>importados {resultado.importados} movimentos</strong>. {resultado.ignorados} movimento(s) foram ignorados, e {resultado.duplicados} foram considerados duplicados. <u onClick={() => setMostrarRelatorio(true)}>Ver relatório</u>.</p>
                    </div>
                </div>}
            </div>
        </div>
    </div>
}