import React, {useEffect} from 'react';
import TabelaPaginada from "../../components/TabelaPaginada";
import {Link, useLocation} from "react-router-dom";
import moment from "moment";
import "moment/locale/pt";
import {get} from "../../YoubizProvider";

import "./styles.scss";

moment.locale("pt");

const Colaboradores = () => {
    let location = useLocation();

    let [pedidosPendentes, setPedidosPendentes] = React.useState(null);

    useEffect(() => {
        get("/utilizador/existem_novos").then(res => {
            setPedidosPendentes(res.contagem)
        })
    }, []);

    let columns = [
        {
            dataField: "numero_colaborador",
            text: 'Id'
        }, {
            dataField: 'nome',
            text: 'Nome',
        }, {
            dataField: 'email',
            text: 'Email'
        }, {
            dataField: "telemovel",
            text: 'Telemóvel'
        }, {
            dataField: 'data_criacao',
            text: 'Data de Registo',
            formatter: data => moment(data).format("DD/MM/YYYY")
        }, {
            dataField: 'data_desativado',
            text: 'Estado',
            formatter(data_desativado) {
                return data_desativado ? <span style={{textTransform: "uppercase", backgroundColor: "#CBA91A40", color: "white", borderRadius: "7px", padding: "8px"}}>Inativo</span> : <span style={{textTransform: "uppercase", backgroundColor: "#4F8B1C40", color: "white", borderRadius: "7px", padding: "8px"}}>Ativo</span>;
            }
        }, {
            dataField: 'action',
            text: 'Ações',
            formatter: (_, {id}) => <Link className="link link_acao" to={"/colaborador/" + id}>Ver colaborador ></Link>
        }
    ];

    return <div className={"Colaboradores"}>
        <div className={"card mb-3"}>
            <div className="card-padding">
                <p className={"font-weight-bold table-title primary-color mr-3 flex-grow-1"}>Novos registos</p>
                {pedidosPendentes !== null && <div>
                    {pedidosPendentes === 0 ? <p className={"mb-0"}>Para já, não existem novos pedidos de registo na plataforma.</p> :
                        <p className={"mb-0"}>Existe{pedidosPendentes !== 1 ? "m" : ""} <strong>{pedidosPendentes}</strong> novo{pedidosPendentes !== 1 ? "s" : ""} pedido{pedidosPendentes !== 1 ? "s" : ""} de registo na plataforma YouBiz. <Link className="link" to={"/comunidade/colaboradores/novos"}>Ver pedidos ></Link></p>}
                </div>}
            </div>
        </div>
        <TabelaPaginada
            numero_resultados={6}
            dataUrl={"/utilizador/listar/colaborador"}
            columns={columns}
            title={"Colaboradores"}
            mostrarExportacao
            pesquisaInicial={new URLSearchParams(location.search).get("pesquisa")}
        />
    </div>;
}

export default Colaboradores;